import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  useTheme,
} from "@mui/material";
import { get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../App.css";
import { DOW, demoKey } from "../../assets/util";
import Snack from "../Snack";
import { MainText, Title } from "../Styled/Text";

const Extender = styled.p(({ isExtended }) => ({
  fontSize: "2em",
  paddingTop: isExtended ? "4vh" : 0,
  paddingBottom: !isExtended ? "4vh" : 0,
  marginTop: "0vh",
  position: "relative",
  top: 0,
  fontFamily: "Comfortaa-Bold",
  animation: !isExtended
    ? "3s ease-in-out 1s infinite normal slidein"
    : undefined,
}));

const MainContainer = styled.div(() => ({
  display: "flex",
  marginTop: "auto",
  marginBottom: "auto",
  alignItems: "center",
  flexDirection: "column",
  height: "calc(100% - 60px)",
  width: "100%",
  wordWrap: "anywhere",
}));

const DayContainer = styled.div(() => ({
  height: "calc(100vh - 60px)",
  top: "60px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));

const Status = {
  READY: "READY",
  NO_MEALS: "NO_MEALS",
  NO_MENUS: "NO_MENUS",
  SUBSCRIBABLE: "SUBSCRIBABLE",
  NOT_VIEWING_MENU: "NOT_VIEWING_MENU",
};

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         FUNCTIONS         /////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const handleMealData = ({ database, state, setState, demoKey, setLoading }) => {
  const weekRef = ref(database, "menus/" + demoKey + "/MOTW");

  get(weekRef).then((weekSnapshot) => {
    const data = weekSnapshot.val();
    setState({
      ...state,
      status: Status.READY,
      recipeData: data,
    });
    setLoading(false);
  });
};

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         COMPONENTS         ////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const IngredientsComponent = ({ ingredients }) => {
  return (
    <FormControl
      sx={{
        marginTop: "4vh",
        display: "flex",
        width: "70%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel>Ingredients</FormLabel>
      <FormGroup>
        {(ingredients || []).map((i, idx) => (
          <FormControlLabel
            key={`ingredients[${idx}]`}
            sx={{ textAlign: "left", margin: "1vh" }}
            control={<Checkbox />}
            label={i}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const InstructionsComponent = ({ instructions }) => {
  return (
    <FormControl
      sx={{
        marginTop: "4vh",
        display: "flex",
        width: "70%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel>Instructions</FormLabel>
      <FormGroup>
        {(instructions || []).map((i, idx) => (
          <FormControlLabel
            key={`instructions[${idx}]`}
            sx={{ textAlign: "left", margin: "1vh" }}
            control={<Checkbox />}
            label={`${idx}: ${i}`}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const RecipeSection = ({ day, recipeData, isActive }) => {
  const [isExtended, setExtended] = useState(false);

  const hasMealData =
    recipeData[day] &&
    (!!recipeData[day].info ||
      (recipeData[day].ingredients || []).length > 0 ||
      (recipeData[day].instructions || []).length > 0);

  return (
    <MainContainer>
      <DayContainer>
        <Title>{day}</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {recipeData[day] && recipeData[day].image && (
            <img
              style={{
                width: "33vh",
                height: "33vh",
                borderRadius: "50%",
              }}
              alt="Meal"
              src={recipeData[day]?.image}
            />
          )}
        </div>
        <MainText>{recipeData[day]?.name || ""}</MainText>
      </DayContainer>
      {hasMealData && (
        <div style={{ width: "100%" }}>
          {isExtended && isActive && (
            <div>
              {recipeData[day] && recipeData[day].info && (
                <>
                  {recipeData[day].info.indexOf("http") === 0 ? (
                    <a
                      target="_blank"
                      href={recipeData[day]?.info}
                      rel="noreferrer"
                    >
                      {recipeData[day]?.info}
                    </a>
                  ) : (
                    <p>{recipeData[day]?.info}</p>
                  )}
                </>
              )}
              {recipeData[day] && recipeData[day].ingredients && (
                <IngredientsComponent
                  ingredients={recipeData[day]?.ingredients}
                />
              )}
              {recipeData[day] && recipeData[day].ingredients && (
                <InstructionsComponent
                  instructions={recipeData[day]?.instructions}
                />
              )}
            </div>
          )}
          {isActive && (
            <Extender
              isExtended={isExtended}
              style={{ transform: `rotate(${isExtended ? "180deg" : "0deg"})` }}
              onClick={() => setExtended(!isExtended)}
            >
              v
            </Extender>
          )}
        </div>
      )}
    </MainContainer>
  );
};

const MealSwiper = ({
  database,
  isDesktop,
  shareKey,
  reload,
  setLoading,
  locale_t,
}) => {
  const [activeIndex, setActiveIndex] = useState(new Date().getDay());
  const [state, setState] = useState({});
  const theme = useTheme();

  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  useEffect(() => {
    setLoading(true);

    handleMealData({
      database,
      setState,
      demoKey,
      setLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoKey, reload]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-theme-color",
      theme.palette.primary.swiper
    );
  }, [theme]);

  return (
    <>
      {state.status === Status.READY && (
        <Swiper
          slidesPerView={isDesktop ? "auto" : undefined}
          centeredSlides={isDesktop && true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          initialSlide={new Date().getDay()}
          navigation={true}
          modules={[Pagination, Navigation]}
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {DOW.map((day, index) => (
            <SwiperSlide key={`DOW[${index}]`}>
              <RecipeSection
                day={day}
                recipeData={state.recipeData}
                isActive={activeIndex === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={"success"}
      />
    </>
  );
};

export default MealSwiper;
