export const Title = ({ children }) => <p className={"MOTD-day"}>{children}</p>;

export const MainText = ({ children, style }) => (
  <p style={style} className={"MOTD-meal"}>{children}</p>
);

export const SubText = ({ children, style }) => (
  <p style={style} className={"MOTD-subtext"}>{children}</p>
)

export const SecondaryText = ({ children, style }) => (
  <p style={style} className={"MOTD-secondaryText"}>{children}</p>
);