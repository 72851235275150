import { Button, Dialog, DialogActions } from "@mui/material";
import { get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import "../../App.css";
import { ShoppingListContent } from "./Modal";

// TODO Implement shopping list into the metadata of the shareKey.
// TODO Consider writing the shopping list myself using open source
// tools online.
// Katie Zhang's medium article explains in a great way how to do this:
//    https://kzz.medium.com/how-i-automated-the-creation-of-my-grocery-list-from-a-bunch-of-recipe-websites-with-python-90d15e5c0c83
//
// Further, Zack Schollz has a library for extracting ingredient lists from any given recipe online:
//    https://schollz.com/tinker/ingredients/
//
// I should look to expand upon Zack Schollz's library and see if I can add a manual list of ingredients
//

const ListModal = ({
  showShoppingList,
  setShowShoppingList,
  auth,
  database,
  shareKey,
  locale_t,
}) => {
  const [menuData, setMenuData] = useState(undefined);

  useEffect(() => {
    const fetchData = async () => {
      if (!!auth?.currentUser) {
        const weekRef = ref(database, "menus/" + shareKey + "/MOTW");

        const data = (await get(weekRef)).val();
        setMenuData(data);
      }
    };

    fetchData();
  }, [auth, database, shareKey]);

  const handleDone = () => {
    setShowShoppingList(false);
  };

  return (
    <Dialog open={showShoppingList}>
      <ShoppingListContent locale_t={locale_t} menuData={menuData} />
      <DialogActions>
        <Button onClick={handleDone} color="primary">
          {locale_t("button.done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListModal;
