import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Divider, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { child, get, push, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import styledComponent from "styled-components";
import Snack from "../../Components/Snack";
import { MainText, Title } from "../../Components/Styled/Text";
import SubscribableComponent, {
  subscribeToMenu,
} from "../../Components/Subscribable";
import { usePrompt } from "../../assets/NavHooks";
import validate from "./validate";

const Status = {
  READY: "READY",
  NOT_AUTHOR: "NOT_AUTHOR",
  NOT_SUBSCRIBED: "NOT_SUBSCRIBED",
};

const MealContainer = styledComponent.div(({ isDesktop }) => ({
  display: "flex",
  margin: "8px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: isDesktop ? "row" : "column",
}));

const MenuContainer = styledComponent.div(({ isDesktop, color }) => ({
  zIndex: 8,
  position: "sticky",
  top: "60px",
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: color,
}));

const FieldContainer = styledComponent.div(({ isDesktop }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: isDesktop ? "40%" : "100%",
}));

const FormScreen = styledComponent.div(() => ({
  position: "absolute",
  top: "70px",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
}));

const MessageScreen = styledComponent.div`
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 4;
`;

const ImageContainer = styledComponent.div`
height: 300px;
width: 300px;
border-radius: 50%;
border-color: #111111;
border-style: solid;
border-width: 0.5px;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`;

const ButtonContainer = styledComponent.div(({ color }) => ({
  position: "sticky",
  zIndex: 8,
  bottom: "0px",
  backgroundColor: color,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "100%",
}));

const FormSection = ({ fields, index, meal, isDesktop, locale_t }) => (
  <FieldContainer isDesktop={isDesktop}>
    <Field
      id={`${meal}.name`}
      name={`${meal}.name`}
      multiline
      component={renderTextField}
      label={locale_t("label.field.name")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`${meal}.info`}
      name={`${meal}.info`}
      multiline
      component={renderTextField}
      label={locale_t("label.field.info")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`${meal}.image`}
      name={`${meal}.image`}
      multiline
      component={renderTextField}
      label={locale_t("label.field.imageSource")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`${meal}.ingredients`}
      name={`${meal}.ingredients`}
      multiline
      component={renderTextField}
      helperText={locale_t("label.field.helperText.ingredients")}
      label={locale_t("label.field.ingredients")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`${meal}.instructions`}
      name={`${meal}.instructions`}
      helperText={locale_t("label.field.helperText.instructions")}
      multiline
      component={renderTextField}
      label={locale_t("label.field.instructions")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Button variant={"outlined"} onClick={() => fields.remove(index)}>
      {locale_t("button.remove")}
    </Button>
  </FieldContainer>
);

const ImageSection = ({ images, index, locale_t }) => {
  let image;
  if (images) {
    image = images[index];
  }
  return (
    <ImageContainer>
      {image && (
        <img
          style={{ width: "300px", height: "300px", borderRadius: "50%" }}
          alt={locale_t("label.imageAlt.meal")}
          src={image}
        />
      )}
    </ImageContainer>
  );
};

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    sx={{ width: custom.isdesktop === "true" ? 400 : "100%" }}
    margin="dense"
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const onSubmit = (
  values,
  _,
  { auth, database, shareKey, onSave, setSnackbar, locale_t }
) => {
  const recipes = values.recipes || [];

  const hasRecipe = recipes.every((r) => Object.keys(r).length > 0);
  const formattedValues = {
    ...values,
    ...(recipes.length > 0 &&
      hasRecipe && {
        recipes: recipes.map((r) => ({
          ...r,
          ingredients: r.ingredients ? r.ingredients.split(/\r?\n/) : null,
          instructions: r.instructions ? r.instructions.split(/\r?\n/) : null,
        })),
      }),
  };

  let state = "updated";

  if (!shareKey) {
    shareKey = push(child(ref(database), "menus")).key;
    state = "created";
  }

  const menusRef = ref(database, "menus/" + shareKey);
  const usersRef = ref(
    database,
    "users/" + auth.currentUser.uid + "/menus/" + shareKey
  );

  set(menusRef, {
    ...formattedValues,
    shareKey,
  }).then(() => {
    set(usersRef, { shareKey, name: values.menu, author: true }).then(() => {
      if (onSave) {
        onSave();
      }
      setSnackbar((prev) => [
        ...prev,
        {
          message: locale_t("info.snack.recipeUpdated", {
            state,
            name: values.menu,
          }),
          key: new Date().getTime(),
        },
      ]);
    });
  });
};

const renderMeals = ({
  theme,
  fields,
  meta: { error, submitFailed },
  recipes,
  isDesktop,
  images,
  locale_t,
}) => (
  <>
    {fields.map((meal, index) => {
      return (
        <div key={index}>
          <MealContainer isDesktop={isDesktop}>
            {index % 2 === 0 && isDesktop ? (
              <>
                <FormSection
                  index={index}
                  meal={meal}
                  fields={fields}
                  isDesktop={isDesktop}
                  locale_t={locale_t}
                />
                <ImageSection
                  index={index}
                  images={images}
                  locale_t={locale_t}
                />
              </>
            ) : (
              <>
                <ImageSection
                  index={index}
                  images={images}
                  locale_t={locale_t}
                />
                <FormSection
                  index={index}
                  meal={meal}
                  fields={fields}
                  isDesktop={isDesktop}
                  locale_t={locale_t}
                />
              </>
            )}
          </MealContainer>
          <Divider variant="middle" />
        </div>
      );
    })}
    <ButtonContainer color={theme.palette.background.recipeFooter}>
      <Button
        sx={{ margin: "4px" }}
        variant={"outlined"}
        onClick={() => {
          fields.push({});

          setTimeout(() => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          }, 200);
        }}
      >
        {locale_t("button.addRecipe")}
      </Button>
      <Button sx={{ margin: "4px" }} variant={"outlined"} type="submit">
        {locale_t("button.save")}
      </Button>
    </ButtonContainer>
  </>
);

const ChangeNameComponent = ({
  shareKey,
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  currentMenu,
  locale_t,
}) => {
  return (
    <MessageScreen>
      {currentMenu && currentMenu.name && (
        <Title>
          {locale_t("info.changeName.title", { name: currentMenu.name })}
        </Title>
      )}
      {currentMenu && currentMenu.name && (
        <MainText>
          {locale_t("info.changeName.description", { name: currentMenu.name })}
        </MainText>
      )}
      <TextField
        error={formState.menuName.error}
        sx={{ margin: "1vh" }}
        size="small"
        value={formState.menuName.value}
        label={locale_t("label.field.menuName")}
        placeholder={locale_t("label.field.menuName")}
        helperText={
          formState &&
          formState.menuName &&
          formState.menuName.error &&
          locale_t(`label.field.error.menuName`)
        }
        onChange={(event) =>
          setFormState({
            ...formState,
            menuName: { ...formState.menuName, value: event.target.value },
          })
        }
      />
      <Button
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
        onClick={() =>
          subscribeToMenu({
            shareKey,
            formState,
            setFormState,
            auth,
            database,
            setSnackbar,
            navigate,
            message: locale_t("info.snack.updatedName", {
              previousName: currentMenu.name,
              currentName: formState.menuName.value,
            }),
          })
        }
      >
        {locale_t("button.changeName")}
      </Button>
    </MessageScreen>
  );
};

let RecipesForm = ({
  handleSubmit,
  database,
  initialize,
  auth,
  onSave,
  loading,
  setLoading,
  locale_t,
}) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const theme = useTheme();
  const [recipes, setRecipes] = useState([]);
  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [status, setStatus] = useState("");
  const [menuName, setMenuName] = useState("");
  const [currentMenu, setCurrentMenu] = useState("");
  const [formState, setFormState] = useState({
    menuName: { value: "", error: false },
  });

  const navigate = useNavigate();
  const { shareKey } = useParams();
  const [images, setImages] = useState([]);
  const [responseState] = useState("success");
  usePrompt(
    locale_t("info.modal.recipeSave"),
    !currentMenu || currentMenu.author
  );

  useEffect(() => {
    setFormState({
      ...formState,
      menuName: { ...formState.menuName, value: currentMenu.name },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu]);

  useEffect(() => {
    const imageLinks = [];
    if (recipes) {
      for (var i = 0; i < recipes.length; i++) {
        const image = document.getElementById(`recipes[${i}].image`);
        if (image) {
          imageLinks.push(image.innerHTML);
        }

        setImages(imageLinks);
      }
    }
  }, [recipes]);

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  useEffect(() => {
    const menuRef = ref(database, "menus/" + shareKey);
    setLoading(true);
    setRecipes([]);
    initialize({});

    get(menuRef).then((menuSnapshot) => {
      const menu = menuSnapshot.val();

      if (menu) {
        const userMenuRef = ref(
          database,
          "users/" + auth.currentUser.uid + "/menus/" + shareKey
        );

        get(userMenuRef).then((userMenuSnapshot) => {
          const userMenu = userMenuSnapshot.val();

          if (userMenu) {
            setCurrentMenu(userMenu);
            if (userMenu.author === true) {
              const recipes = (menu.recipes || [{}]).map((r) => ({
                ...r,
                instructions: (r.instructions || []).join("\n"),
                ingredients: (r.ingredients || []).join("\n"),
              }));
              setRecipes(recipes);
              initialize({ ...menu, recipes });
              setLoading(false);
              setStatus(Status.READY);
            } else {
              setStatus(Status.NOT_AUTHOR);
              setLoading(false);
              initialize({ menu: menu.menu });
            }
          } else {
            // Not subscribed
            setStatus(Status.NOT_SUBSCRIBED);
          }
        });
      } else {
        initialize({ recipes: [{}] });
        setStatus(Status.READY);
      }

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareKey]);

  return (
    <div>
      {status === Status.NOT_AUTHOR && (
        <ChangeNameComponent
          shareKey={shareKey}
          formState={formState}
          setFormState={setFormState}
          auth={auth}
          database={database}
          setSnackbar={setSnackbar}
          navigate={navigate}
          currentMenu={currentMenu}
          locale_t={locale_t}
        />
      )}
      {status === Status.NOT_SUBSCRIBED && (
        <SubscribableComponent
          shareKey={shareKey}
          formState={formState}
          setFormState={setFormState}
          menuName={menuName}
          setMenuName={setMenuName}
          auth={auth}
          database={database}
          setSnackbar={setSnackbar}
          navigate={navigate}
          currentMenu={currentMenu}
          locale_t={locale_t}
        />
      )}
      {status === Status.READY && (
        <FormScreen>
          <form
            onSubmit={handleSubmit((values, dispatch) => {
              onSubmit(values, dispatch, {
                auth,
                database,
                shareKey,
                onSave,
                setSnackbar,
                locale_t,
              });
            })}
          >
            <MenuContainer
              color={theme.palette.background.recipeHeader}
              isDesktop={isDesktop}
            >
              <FieldContainer isDesktop={isDesktop}>
                <Field
                  id={`menu`}
                  name={`menu`}
                  multiline
                  component={renderTextField}
                  label={locale_t("label.field.menuName")}
                  placeholder={locale_t("label.field.menuName")}
                  isdesktop={isDesktop ? "true" : "false"}
                />
              </FieldContainer>
              <Divider sx={{ width: "100%" }}>
                <Chip
                  sx={{ backgroundColor: "#000" }}
                  label={locale_t("label.chip.menuEntries")}
                />
              </Divider>
            </MenuContainer>

            <FieldArray
              name="recipes"
              component={(props) =>
                renderMeals({
                  ...props,
                  theme,
                  recipes,
                  isDesktop,
                  images,
                  locale_t,
                })
              }
            />
          </form>
        </FormScreen>
      )}
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={responseState}
      />
    </div>
  );
};

RecipesForm = reduxForm({
  form: "recipes", // a unique identifier for this form
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RecipesForm);

RecipesForm = connect((state) => ({
  initialValues: {
    recipes: state.recipes,
    randomize: true,
  },
  // recipes: selector(state, "recipes"),
}))(RecipesForm);

export default RecipesForm;
