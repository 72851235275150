import { Divider, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { child, get, push, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import styledComponent from "styled-components";
import Snack from "../../Components/Snack";
import validate from "./validate";

const MealContainer = styledComponent.div(({ isDesktop }) => ({
  display: "flex",
  margin: "8px",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));

const FieldContainer = styledComponent.div(({ isDesktop }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  width: isDesktop ? "80%" : "100%",
}));

const FormScreen = styledComponent.div(() => ({
  top: "70px",
  left: 0,
  right: 0,
  marginLeft: "auto",
  marginRight: "auto",
}));

const ImageContainer = styledComponent.div`
height: 300px;
width: 300px;
border-radius: 50%;
border-color: #111111;
border-style: solid;
border-width: 0.5px;
justify-content: center;
align-items: center;
display: flex;
flex-direction: column;
`;

const FormSection = ({ meal, isDesktop, locale_t }) => (
  <FieldContainer isDesktop={isDesktop}>
    <Field
      id={`recipe.name`}
      name={`recipe.name`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.name")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.info`}
      name={`recipe.info`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.info")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.image`}
      name={`recipe.image`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.imageSource")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.timeToCook`}
      name={`recipe.timeToCook`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.timeToCook")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.servingSize`}
      name={`recipe.servingSize`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.servingSize")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.caloriesPerServing`}
      name={`recipe.caloriesPerServing`}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.caloriesPerServing")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.ingredients`}
      name={`recipe.ingredients`}
      multiline
      component={RenderTextField}
      helperText={locale_t("label.field.helperText.ingredients")}
      label={locale_t("label.field.ingredients")}
      isdesktop={isDesktop ? "true" : "false"}
    />
    <Field
      id={`recipe.instructions`}
      name={`recipe.instructions`}
      helperText={locale_t("label.field.helperText.instructions")}
      multiline
      component={RenderTextField}
      label={locale_t("label.field.instructions")}
      isdesktop={isDesktop ? "true" : "false"}
    />
  </FieldContainer>
);

const ImageSection = ({ image, locale_t }) => {
  return (
    <ImageContainer>
      {image && (
        <img
          style={{ width: "300px", height: "300px", borderRadius: "50%" }}
          alt={locale_t("label.imageAlt.meal")}
          src={image}
        />
      )}
    </ImageContainer>
  );
};

const RenderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    sx={{ width: custom.isdesktop === "false" ? "100%" : "100%" }}
    margin="dense"
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

const saveRecipe = (
  values,
  _,
  {
    auth,
    database,
    shareKey,
    onSave,
    setSnackbar,
    locale_t,
    id,
    setNeedsUpdate,
  }
) => {
  const recipe = values.recipe || [];
  const formattedValues = {
    ...values,
    ...{
      recipe: {
        ...recipe,
        ingredients: recipe.ingredients
          ? recipe.ingredients.split(/\r?\n/)
          : null,
        instructions: recipe.instructions
          ? recipe.instructions.split(/\r?\n/)
          : null,
      },
    },
  };

  if (!shareKey) {
    shareKey = push(child(ref(database), "menus")).key;
  }

  // const menusRef = ref(database, "menus/" + shareKey);
  const recipeRef = ref(
    database,
    "menus/" + shareKey + "/recipes/" + recipe.id
  );

  set(recipeRef, { ...formattedValues.recipe }).then(() => {
    onSave?.();
    setNeedsUpdate?.(true);
    setSnackbar((prev) => [
      ...prev,
      {
        message: locale_t("info.snack.updatedRecipe"),
        key: new Date().getTime(),
      },
    ]);
  });

  // set(menusRef, {
  //   ...formattedValues,
  //   shareKey,
  // }).then(() => {
  //   set(usersRef, { shareKey, name: values.menu, author: true }).then(() => {
  //     if (onSave) {
  //       onSave();
  //     }
  //     setSnackbar((prev) => [
  //       ...prev,
  //       {
  //         message: locale_t("info.snack.recipeUpdated", {
  //           state,
  //           name: values.menu,
  //         }),
  //         key: new Date().getTime(),
  //       },
  //     ]);
  //   });
  // });
};

const renderMeals = (props) => {
  const { isDesktop, image, locale_t } = props;
  return (
    <>
      <div>
        <MealContainer isDesktop={isDesktop}>
          <ImageSection image={image} locale_t={locale_t} />
          <FormSection isDesktop={isDesktop} locale_t={locale_t} />
        </MealContainer>
        <Divider variant="middle" />
      </div>
    </>
  );
};

let RecipesForm = ({
  handleSubmit,
  database,
  initialize,
  auth,
  onSave,
  shareKey,
  locale_t,
  recipeIndex,
  newRecipe,
  initialValues,
  setNeedsUpdate,
}) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const theme = useTheme();
  const [recipe, setRecipe] = useState([]);
  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [currentMenu, setCurrentMenu] = useState("");
  const [formState, setFormState] = useState({
    menuName: { value: "", error: false },
  });

  const [image, setImage] = useState();
  const [responseState] = useState("success");

  const onSubmit = (values, dispatch) => {
    saveRecipe(values, dispatch, {
      auth,
      database,
      shareKey,
      onSave,
      setSnackbar,
      locale_t,
      recipeIndex,
      setNeedsUpdate,
    });
  };

  useEffect(() => {
    setFormState({
      ...formState,
      menuName: { ...formState.menuName, value: currentMenu.name },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu]);

  useEffect(() => {
    let imageLink;
    if (recipe) {
      const image = document.getElementById(`recipe.image`);
      if (image) {
        imageLink = image.innerHTML;
      }

      setImage(imageLink);
    }
  }, [recipe]);

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  useEffect(() => {
    const menuRef = ref(database, "menus/" + shareKey);
    setRecipe({});
    initialize({});

    get(menuRef).then((menuSnapshot) => {
      const menu = menuSnapshot.val();

      if (menu) {
        const userMenuRef = ref(
          database,
          "users/" + auth.currentUser.uid + "/menus/" + shareKey
        );

        get(userMenuRef).then((userMenuSnapshot) => {
          const userMenu = userMenuSnapshot.val();
          if (userMenu) {
            setCurrentMenu(userMenu);
            if (userMenu.author === true) {
              const safeRecipes = menu.recipes || [];
              const newIndex = safeRecipes.length;

              const id = !!recipeIndex ? recipeIndex : newIndex;
              const recipe =
                safeRecipes[newRecipe && !recipeIndex ? newIndex : recipeIndex];
              const formattedRecipe = {
                ...recipe,
                instructions: (recipe?.instructions || []).join("\n"),
                ingredients: (recipe?.ingredients || []).join("\n"),
                id,
              };
              setRecipe(recipe);
              initialize({
                recipe: formattedRecipe,
                menu: menu.menu,
              });
            } else {
              initialize({ menu: menu.menu });
            }
          } else {
            // Not subscribed
          }
        });
      } else {
        initialize({ recipe: {} });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareKey]);

  return (
    <div>
      <FormScreen>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field
            id="recipe"
            name="recipe"
            component={(props) =>
              renderMeals({
                ...props,
                theme,
                recipe,
                isDesktop,
                image,
                locale_t,
              })
            }
          />
        </Form>
      </FormScreen>
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={responseState}
      />
    </div>
  );
};

RecipesForm = reduxForm({
  form: "recipe", // a unique identifier for this form
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RecipesForm);

RecipesForm = connect((state) => ({
  initialValues: {
    recipe: state.recipe,
    randomize: true,
  },
  // recipes: selector(state, "recipes"),
}))(RecipesForm);

export default RecipesForm;
