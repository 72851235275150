// Adapted from here:
// https://github.com/codebox/js-planet-phase

import React, { useMemo } from "react";
import styled from "styled-components";

const defaultConfig = {
  shadowColour: "#0006", // CSS background-colour value for the shaded part of the disc
  lightColour: "#ffffff88", // CSS background-colour value for the illuminated part of the disc
  earthshine: 0.0, // between 0 and 1, the amount of light falling on the shaded part of the disc 0=none, 1=full illumination
  blur: 4, // amount of blur on the terminator in pixels, 0=no blur
};

const calcInner = (outerDiameter, semiPhase) => {
  const absPhase = Math.abs(semiPhase);
  const n = ((1 - absPhase) * outerDiameter) / 2 || 0.01;
  const innerRadius = n / 2 + (outerDiameter * outerDiameter) / (8 * n);

  return {
    d: innerRadius * 2,
    o:
      semiPhase > 0
        ? outerDiameter / 2 - n
        : -2 * innerRadius + outerDiameter / 2 + n,
  };
};

const ImageContainer = styled.img(({ size }) => {
  return { width: size, height: size, borderRadius: "50%" };
});

const MoonPhase = ({ styles }) => {
  return (
    <div style={styles.outerStyle}>
      <div style={styles.innerStyle} />
    </div>
  );
};

export const PlanetPhasev2 = ({
  size,
  imageSource,
  time,
  phase,
  config = defaultConfig,
}) => {
  const isWaxing = false;

  const fullConfig = { ...defaultConfig, ...config };
  const { innerDiameter, innerOffset, outerDiameter, outerColor, innerColor } =
    useMemo(() => {
      let outerColorInternal, innerColorInternal;
      let phaseValue = phase;

      if (phase < 0.5) {
        outerColorInternal = fullConfig.lightColour;
        innerColorInternal = fullConfig.shadowColour;
        if (isWaxing) {
          phaseValue *= -1;
        }
      } else {
        outerColorInternal = fullConfig.shadowColour;
        innerColorInternal = fullConfig.lightColour;
        phaseValue = 1 - phaseValue;
        if (!isWaxing) {
          phaseValue *= -1;
        }
      }

      const innerVals = calcInner(size, phaseValue * 2);

      return {
        innerDiameter: innerVals.d,
        innerOffset: innerVals.o,
        outerDiameter: size,
        outerColor: outerColorInternal,
        innerColor: innerColorInternal,
      };
    }, [
      fullConfig.lightColour,
      fullConfig.shadowColour,
      isWaxing,
      phase,
      size,
    ]);

  const moonStyles = useMemo(() => {
    const blurSize = fullConfig.blur;

    const blurredDiameter = innerDiameter - blurSize;
    const blurredOffset = innerOffset + blurSize / 2;

    const outerStyle = {
      position: "absolute",
      height: `${outerDiameter}px`,
      width: `${outerDiameter}px`,
      border: "0px solid #444444",
      boxShadow: "0px 0px 8px 8px #888888",
      backgroundColor: phase >= 0.5 ? outerColor : undefined,
      borderRadius: `${outerDiameter / 2}px`,
      overflow: "hidden",
    };

    const innerStyle = {
      position: "absolute",
      borderRadius: `${blurredDiameter / 2}px`,
      height: `${blurredDiameter}px`,
      width: `${blurredDiameter}px`,
      left: `${blurredOffset}px`,
      top: `${(outerDiameter - blurredDiameter) / 2}px`,
      //   boxShadow: `0px 0px ${blurSize}px ${blurSize}px ${innerColor}`,
      opacity: 1 - fullConfig.earthshine,
      ...(phase >= 0.5 || phase <= -0.5
        ? {
            backgroundImage: `url(${imageSource})`,
            backgroundPosition: `center ${
              isWaxing ? "left" : "left"
            } calc(0px - ${innerOffset}px)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: `${size}px ${size}px`,
          }
        : { backgroundColor: phase < 0.5 ? innerColor : outerColor }),
    };

    return {
      innerStyle,
      outerStyle,
    };
  }, [
    fullConfig.blur,
    fullConfig.earthshine,
    imageSource,
    innerColor,
    innerDiameter,
    innerOffset,
    isWaxing,
    outerColor,
    outerDiameter,
    phase,
    size,
  ]);

  return (
    <div style={{ width: size, height: size }}>
      <MoonPhase styles={moonStyles} />
      <ImageContainer src={imageSource} size={size} />
    </div>
  );
};
