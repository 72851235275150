import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { PiForkKnifeLight } from "react-icons/pi";
import { TbFlame } from "react-icons/tb";
import { NavLink as Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../App.css";
import { PlanetPhasev2 } from "../../assets/PlanetPhase";
import { RotatingComponent } from "../../assets/RotatingComponent";
import logo from "../../assets/sm.png";
import { ConfigStatus, DOW, getMeals } from "../../assets/util";
import WizardModal from "../Modals/Modal";
import Snack from "../Snack";
import { Screen } from "../Styled/Containers";
import { MainText, Title } from "../Styled/Text";
import SubscribableComponent from "../Subscribable";

///////////////////////////////////////
///// API KEY -- ChatGPT
//< REDACTED >
///// API KEY -- ChatGPT
///////////////////////////////////////
const Extender = styled.p(({ isExtended }) => ({
  fontSize: "2em",
  paddingTop: isExtended ? "4vh" : 0,
  paddingBottom: !isExtended ? "4vh" : 0,
  marginTop: "0vh",
  position: "relative",
  top: 0,
  fontFamily: "Comfortaa-Bold",
  animation: !isExtended
    ? "3s ease-in-out 1s infinite normal slidein"
    : undefined,
}));

const MainContainer = styled.div(() => ({
  display: "flex",
  marginTop: "auto",
  marginBottom: "auto",
  alignItems: "center",
  flexDirection: "column",
  height: "calc(100% - 60px)",
  width: "100%",
  wordWrap: "anywhere",
}));

const DayContainer = styled.div(() => ({
  height: "calc(100vh - 60px)",
  top: "60px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  textAlign: "center",
}));

const DetailsContainer = styled.div(() => ({}));

const DetailRow = styled.div(() => ({
  flexDirection: "row",
  display: "flex",
}));

const StyledDetail = styled.div(() => ({
  flexDirection: "row",
  display: "flex",
}));

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         FUNCTIONS         /////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const handleMealData = ({
  auth,
  database,
  state,
  setState,
  shareKey,
  setLoading,
  handleOpenModal,
}) => {
  getMeals({ auth, database, shareKey, updatingServer: false }).then(
    (newState) => {
      // We got our meals. Now, if it's the day for the menu change, display the wizard.
      if (newState.ack === false) {
        handleOpenModal(newState.recipeData);
      }
      setState({ ...state, ...newState });
      setLoading(false);
    }
  );
};

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         COMPONENTS         ////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const detailIconStyle = { paddingLeft: "0.5rem", paddingRight: "0.25rem" };
const detailTextStyle = { paddingLeft: "0.25rem", paddingRight: "0.5rem" };

const DetailsComponent = ({ detail, iconComponent }) => {
  return (
    <StyledDetail>
      {iconComponent(detailIconStyle)}
      <Typography style={detailTextStyle}>{detail}</Typography>
    </StyledDetail>
  );
};

const IngredientsComponent = ({ ingredients }) => {
  return (
    <FormControl
      sx={{
        marginTop: "4vh",
        display: "flex",
        width: "70%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel>Ingredients</FormLabel>
      <FormGroup>
        {(ingredients || []).map((i, idx) => (
          <FormControlLabel
            key={`ingredients[${idx}]`}
            sx={{ textAlign: "left", margin: "1vh" }}
            control={<Checkbox />}
            label={i}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const InstructionsComponent = ({ instructions }) => {
  return (
    <FormControl
      sx={{
        marginTop: "4vh",
        display: "flex",
        width: "70%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
      component="fieldset"
      variant="standard"
    >
      <FormLabel>Instructions</FormLabel>
      <FormGroup>
        {(instructions || []).map((i, idx) => (
          <FormControlLabel
            key={`instructions[${idx}]`}
            sx={{ textAlign: "left", margin: "1vh" }}
            control={<Checkbox />}
            label={`${idx}: ${i}`}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};

const Ring = styled.div(({ size }) => ({
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: "50%",
  width: size,
  height: size / 10,
  border: "4px solid #FFFFFF44",
  borderTop: "0px solid white",
}));

const RotatedRing = ({ rotation, size }) => (
  <RotatingComponent __rotation={rotation} size={size}>
    {(size) => <Ring size={size} />}
  </RotatingComponent>
);

const Rings = ({ rotation }) => (
  <>
    <RotatedRing rotation={rotation} size={320} />
    <RotatedRing rotation={rotation} size={360} />
    <RotatedRing rotation={rotation} size={440} />
    <RotatedRing rotation={rotation} size={480} />
    <RotatedRing rotation={rotation} size={600} />
  </>
);

const RecipeSection = ({ day, recipeData, isActive, time, phase }) => {
  const [isExtended, setExtended] = useState(false);

  const hasMealData =
    recipeData[day] &&
    (!!recipeData[day].info ||
      (recipeData[day].ingredients || []).length > 0 ||
      (recipeData[day].instructions || []).length > 0);

  return (
    <MainContainer>
      <DayContainer>
        <Title>{day}</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            height: "33vh",
            alignItems: "center",
          }}
        >
          {recipeData[day] && recipeData[day].image && (
            <PlanetPhasev2
              size={200}
              phase={phase}
              time={time}
              imageSource={recipeData[day]?.image ?? logo}
            />
          )}
          <Rings rotation={-30} />
        </div>
        <MainText>{recipeData[day]?.name || ""}</MainText>
        <DetailsContainer>
          <DetailRow>
            <DetailsComponent
              iconComponent={(style) => <FaRegClock style={style} />}
              detail={recipeData[day]?.timeToCook ?? "--"}
            />
            <DetailsComponent
              iconComponent={(style) => <PiForkKnifeLight style={style} />}
              detail={recipeData[day]?.servingSize ?? "--"}
            />
            <DetailsComponent
              iconComponent={(style) => <TbFlame style={style} />}
              detail={recipeData[day]?.caloriesPerServing ?? "--"}
            />
          </DetailRow>
        </DetailsContainer>
      </DayContainer>
      {hasMealData && (
        <div style={{ width: "100%" }}>
          {isExtended && isActive && (
            <div>
              {recipeData[day] && recipeData[day].info && (
                <>
                  {recipeData[day].info.indexOf("http") === 0 ? (
                    <a
                      target="_blank"
                      href={recipeData[day]?.info}
                      rel="noreferrer"
                    >
                      {recipeData[day]?.info}
                    </a>
                  ) : (
                    <p>{recipeData[day]?.info}</p>
                  )}
                </>
              )}
              {recipeData[day] && recipeData[day].ingredients && (
                <IngredientsComponent
                  ingredients={recipeData[day]?.ingredients}
                />
              )}
              {recipeData[day] && recipeData[day].ingredients && (
                <InstructionsComponent
                  instructions={recipeData[day]?.instructions}
                />
              )}
            </div>
          )}
          {isActive && (
            <Extender
              isExtended={isExtended}
              style={{ transform: `rotate(${isExtended ? "180deg" : "0deg"})` }}
              onClick={() => setExtended(!isExtended)}
            >
              v
            </Extender>
          )}
        </div>
      )}
    </MainContainer>
  );
};

const NeedsMenuComponent = ({ locale_t }) => (
  <Screen>
    <Title>{locale_t("info.noMenus.title")}</Title>
    <MainText>{locale_t("info.noMenus.description")}</MainText>
  </Screen>
);

const ViewMenuComponent = ({ locale_t }) => (
  <Screen>
    <Title>{locale_t("info.viewMenu.title")}</Title>
    <MainText>{locale_t("info.viewMenu.description")}</MainText>
    <MainText>{locale_t("info.viewMenu.subdescription")}</MainText>
  </Screen>
);

const NeedsMealsComponent = ({ menus, shareKey, locale_t }) => {
  const currentMenu = menus.filter((m) => m.shareKey === shareKey)[0];

  const description =
    currentMenu &&
    (currentMenu.author
      ? locale_t("info.noRecipes.description", { name: currentMenu.name })
      : locale_t("info.noRecipes.subscribableDescription"));

  return (
    <Screen>
      <Title>{locale_t("info.noRecipes.title")}</Title>
      {currentMenu && <MainText>{description}</MainText>}
      {description === locale_t("info.noRecipes.subscribableDescription") && (
        <MainText>
          {locale_t("info.noRecipes.subscribableSubdescription", {
            name: currentMenu.name,
          })}
        </MainText>
      )}
      {currentMenu.author && (
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
          component={Link}
          to={`/recipes/${shareKey}`}
        >
          {locale_t("button.addRecipe")}
        </Button>
      )}
    </Screen>
  );
};

const MealSwiper = ({
  auth,
  database,
  isDesktop,
  shareKey,
  reload,
  setLoading,
  locale_t,
}) => {
  const [activeIndex, setActiveIndex] = useState(new Date().getDay());
  const [state, setState] = useState({});
  const [formState, setFormState] = useState({
    menuName: { value: "", error: false },
  });

  const theme = useTheme();

  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const navigate = useNavigate();
  const [modalData, setModalData] = useState(undefined);

  const handleOpenModal = (mealData) => {
    setModalData(mealData);
  };

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  useEffect(() => {
    setLoading(true);

    handleMealData({
      auth,
      database,
      setState,
      shareKey,
      setLoading,
      handleOpenModal,
    });
    // setState will cause it to consistently re-render; never good.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareKey, reload]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-theme-color",
      theme.palette.primary.swiper
    );
  }, [theme]);

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      const d = new Date();
      const curr = d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();

      setTime(curr);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const phase = useMemo(() => {
    const maxWax = 12 * 3600;
    const current = time / maxWax;
    console.log(current);
    if (current > 1) {
      return 2 - current;
    } else {
      return current;
    }
  }, [time]);

  return (
    <>
      {state.status === ConfigStatus.NO_MEALS && state.menus && (
        <NeedsMealsComponent
          menus={state.menus}
          shareKey={shareKey}
          locale_t={locale_t}
        />
      )}
      {state.status === ConfigStatus.SUBSCRIBABLE && (
        <SubscribableComponent
          shareKey={shareKey}
          formState={formState}
          setFormState={setFormState}
          auth={auth}
          database={database}
          setSnackbar={setSnackbar}
          navigate={navigate}
          locale_t={locale_t}
        />
      )}
      {state.status === ConfigStatus.NOT_VIEWING_MENU && (
        <ViewMenuComponent locale_t={locale_t} />
      )}
      {state.status === ConfigStatus.NO_MENUS && (
        <NeedsMenuComponent locale_t={locale_t} />
      )}
      {state.status === ConfigStatus.READY && (
        <Swiper
          slidesPerView={isDesktop ? "auto" : undefined}
          centeredSlides={isDesktop && true}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          initialSlide={new Date().getDay()}
          navigation={true}
          modules={[Pagination, Navigation]}
          onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {DOW.map((day, index) => (
            <SwiperSlide key={`DOW[${index}]`}>
              <RecipeSection
                time={time}
                phase={phase}
                day={day}
                recipeData={state.recipeData}
                isActive={activeIndex === index}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={"success"}
      />
      <WizardModal
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
        data={modalData}
      />
    </>
  );
};

export default MealSwiper;
