import { get, ref, set } from "firebase/database";

export const DOW = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

const baseIngredients = [
  "bean",
  "broth",
  "cheese",
  "chicken",
  "chilies",
  "chips",
  "cilantro",
  "corn",
  "garlic",
  "juice",
  "oil",
  "onion",
  "rice",
  "zest",
];

const measurements = ["lb", "tbsp", "cup", "oz", "ounce", "tsp", ""];

// const seasoning = [
//   "salt",
//   "black pepper",
//   "cilantro",
//   "cumin",
//   "paprika",
//   "oregano",
//   "coriander",
//   "pepper",
// ];

const sortByIngredient = (ingredientList) => {
  const sortedByIngredient = baseIngredients.map((ingredient) =>
    ingredientList.filter(
      (i) =>
        i.toLowerCase().includes(ingredient) &&
        baseIngredients.every(
          (baseIngredient) =>
            i.toLowerCase().lastIndexOf(ingredient) >=
            i.toLowerCase().lastIndexOf(baseIngredient)
        )
    )
  );
  return sortedByIngredient;
};

const sortByFull = (sortedByIngredient) => {
  const sortedByFull = sortedByIngredient.map((ingredientGroup) =>
    ingredientGroup.sort((a, b) => a.localeCompare(b))
  );
  return sortedByFull;
};

const sortByAmount = (sortedByFull) => {
  const sortedByAmount = sortedByFull.map((s) =>
    measurements.map((measurement) =>
      s.filter(
        (i) =>
          i.toLowerCase().includes(measurement) &&
          measurements
            .slice(0, -1)
            .every(
              (m) =>
                i.toLowerCase().lastIndexOf(measurement) >=
                i.toLowerCase().lastIndexOf(m)
            )
      )
    )
  );
  sortedByAmount.forEach((category) => {
    category.slice(0, -1).forEach((amount) => {
      amount.forEach((ingredient) => {
        if (category[category.length - 1].includes(ingredient)) {
          category[category.length - 1].splice(
            category[category.length - 1].indexOf(ingredient),
            1
          );
        }
      });
    });
  });

  return sortedByAmount;
};

export const sortIngredients = (ingredientList) => {
  if (ingredientList) {
    const sortedByIngredient = sortByIngredient(ingredientList);
    const sortedByFull = sortByFull(sortedByIngredient);
    const sortedByAmount = sortByAmount(sortedByFull);
    console.log(sortedByAmount);

    return sortedByAmount;
  }
  return null;
};

export const getMenus = ({ user, database, setMenus, setLoading }) => {
  if (user) {
    const menusRef = ref(database, "users/" + user.uid + "/menus");
    get(menusRef).then((menuSnapshot) => {
      const menus = Object.values(menuSnapshot.val() || []);
      setMenus(menus.length > 0 ? menus : []);
      if (setLoading) {
        setLoading(false);
      }
    });
  }
};

export const getShoppingList = ({ user, setLoading }) => {
  // Since we have to make a request to our cloud function anyways, make that the source-of-truth for the shopping list.
};

export const NUMBERED_DAYS = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const getNextGeneration = (day = 7, date = new Date()) => {
  const next = new Date();
  next.setHours(0, 0, 0, 0);
  const offset = day % 7;

  next.setDate(
    date.getDate() -
      date.getDay() +
      (offset <= date.getDay() ? 7 + offset : offset)
  );
  return next.getTime();
};

export const demoKey = "-NB3higphsiYdnTaX6PS";

export const ConfigStatus = {
  READY: "READY",
  NO_MEALS: "NO_MEALS",
  NO_MENUS: "NO_MENUS",
  SUBSCRIBABLE: "SUBSCRIBABLE",
  NOT_VIEWING_MENU: "NOT_VIEWING_MENU",
};

export const generateMealIds = (total) => {
  const meals = [];
  for (let i = 0; i < 7; i++) {
    var currInt = getRandomInt(total);
    if (total > 7) {
      while (meals.includes(currInt)) {
        currInt = getRandomInt(total);
      }
    }
    meals.push(currInt);
  }
  return meals;
};

export const getMeals = async ({
  auth,
  database,
  shareKey,
  ids,
  forcePush,
}) => {
  const userSnapshot = await get(
    ref(database, "users/" + auth?.currentUser?.uid + "/menus")
  );
  const userMenus = Object.values(userSnapshot.val() || []);

  if (userMenus && userMenus.length > 0) {
    const menu = userMenus.filter((m) => m.shareKey === shareKey)[0];
    if (menu) {
      // Load the MOTW
      const weekRef = ref(database, "menus/" + shareKey + "/MOTW");
      const data = (await get(weekRef)).val();

      const generatedTime = new Date().getTime();

      const nextGeneration = getNextGeneration(menu.generationDay);

      if (
        ids ||
        !data ||
        (data.until < nextGeneration && generatedTime > data.until)
      ) {
        const mealsRef = ref(database, "menus/" + shareKey + "/recipes");
        const snapshot = await get(mealsRef);

        const meals = snapshot.val();
        if (meals && meals.length > 0) {
          const mealIndices =
            (ids || []).length > 0 ? ids : generateMealIds(meals.length);
          const weekConfig = DOW.map((day, index) => ({
            [`${day}`]: meals[mealIndices[index]],
          })).reduce((prev, curr) => {
            return { ...prev, ...curr };
          }, {});

          const rerollIds = data?.rerollIds?.every((item) =>
            (ids || []).includes(item)
          )
            ? ids
            : generateMealIds(meals.length);

          const recipeData = {
            ...weekConfig,
            rerollIds,
            generatedTime,
            ack: false,
            until: nextGeneration,
          };

          if (ids !== rerollIds || forcePush) {
            await set(weekRef, recipeData);
          }

          return {
            ack: data?.ack ?? false,
            status: ConfigStatus.READY,
            recipeData,
            menus: userMenus,
          };
        } else {
          return {
            status: ConfigStatus.NO_MEALS,
            menus: userMenus,
          };
        }
      } else {
        return {
          rerollIds: data?.rerollIds,
          ack: data?.ack ?? false,
          status: ConfigStatus.READY,
          recipeData: data,
          menus: userMenus,
        };
      }
    } else {
      if (shareKey) {
        // Not subscribed to menu -- cannot view until subscribed.
        return {
          status: ConfigStatus.SUBSCRIBABLE,
        };
      } else {
        // We have menus, but we aren't trying to view an unsubscribed menu and we aren't viewing one of ours.
        // Let's tell them to view the side menu for menus
        return {
          status: ConfigStatus.NOT_VIEWING_MENU,
        };
      }
    }
  } else {
    if (shareKey) {
      // No menus, but we are acttively trying to subscribe.
      return {
        status: ConfigStatus.SUBSCRIBABLE,
      };
    } else {
      // We have no menus
      return {
        status: ConfigStatus.NO_MENUS,
      };
    }
  }
};
