import {
  faCalendarDays,
  faClipboard,
  faPenToSquare,
  faRectangleList,
} from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Divider, TextField, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleAuthProvider, signOut } from "firebase/auth";
import KofiButton from "kofi-button";
import React, { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { signInWithGoogle } from "../../Routes/Home";
import logo from "../../assets/sm.png";
import { demoKey } from "../../assets/util";
import DayToggleModal from "../Modals/DayToggleModal";
import ListModal from "../Modals/ListModal";
import RecipeModal from "../Modals/RecipeModal";
import ThemeModal from "../Modals/ThemeModal";
import Snack from "../Snack";
import { subscribeToMenu } from "../Subscribable";
import {
  ActionButton,
  ActionButtonContainer,
  MainLink,
  MenuText,
  Nav,
  NavContainer,
  NavExtendedImage as NavExpandedImage,
  NavExpandedMenu,
  NavImage,
  NavText,
  Navbars,
} from "./NavbarElements";

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         FUNCTIONS         /////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const handleLogin = ({ loggedIn, setSnackbar, locale_t }) => {
  setSnackbar((prev) => [
    ...prev,
    {
      message: locale_t("info.snack.pleaseSignIn"),
      key: new Date().getTime(),
    },
  ]);
};

const subscribe = ({
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  setResponseState,
  setExpanded,
  locale_t,
}) => {
  setFormState({
    ...formState,
    menuKey: { ...formState.menuKey, error: false },
    menuName: { ...formState.menuName, error: false },
  });

  if (formState) {
    const menuKey = formState.menuKey.value;
    const menuName = formState.menuName.value;

    if (menuKey && menuName) {
      subscribeToMenu({
        shareKey: menuKey,
        menuName,
        formState,
        setFormState,
        auth,
        database,
        setSnackbar,
        navigate,
        setExpanded,
        setResponseState,
        locale_t,
      });
    } else {
      setFormState({
        ...formState,
        menuKey: { ...formState.menuKey, error: !menuKey },
        menuName: { ...formState.menuName, error: !menuName },
      });
    }
  }
};

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////         COMPONENTS         ////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const NavButton = ({ label, link, onClick, startIcon }) => (
  <div style={{ width: "80%", display: "flex" }}>
    <ActionButton
      startIcon={startIcon}
      variant="outlined"
      component={Link}
      to={link}
      onClick={onClick}
    >
      {label}
    </ActionButton>
  </div>
);

const FormTextField = ({ name, formState, setFormState, locale_t }) => {
  return (
    <TextField
      error={formState[name].error}
      sx={{ margin: "1vh" }}
      size="small"
      value={formState && formState[name] && formState[name].value}
      label={locale_t(`label.field.${name}`)}
      placeholder={locale_t(`label.field.${name}`)}
      helperText={
        formState &&
        formState[name] &&
        formState[name].error &&
        locale_t(`label.field.error.${name}`)
      }
      onChange={(event) =>
        setFormState({
          ...formState,
          [name]: { ...formState[name], value: event.target.value },
        })
      }
    />
  );
};

const SubscribeMenu = ({
  loggedIn,
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  setResponseState,
  setExpanded,
  locale_t,
}) => (
  <div className="mobile-nav" style={{}}>
    <Divider variant="middle" sx={{ margin: "1vh" }}>
      <Chip
        sx={{ backgroundColor: "#000000" }}
        label={locale_t("label.chip.subscribe")}
      />
    </Divider>
    <div
      style={{
        margin: "2vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <FormTextField
          name={"menuKey"}
          formState={formState}
          setFormState={setFormState}
          locale_t={locale_t}
        />
        <FormTextField
          name={"menuName"}
          formState={formState}
          setFormState={setFormState}
          locale_t={locale_t}
        />
        <Button
          variant="outlined"
          startIcon={<FontAwesomeIcon icon={faCircleCheck} />}
          onClick={() => {
            if (!loggedIn) {
              handleLogin({ setSnackbar, locale_t });
            } else {
              subscribe({
                formState,
                setFormState,
                auth,
                database,
                setSnackbar,
                navigate,
                setResponseState,
                setExpanded,
                locale_t,
              });
            }
          }}
        >
          {locale_t("button.subscribe")}
        </Button>
      </div>
    </div>
  </div>
);

const Navbar = ({
  menus,
  shareKey,
  auth,
  database,
  onThemeChange,
  loggedIn,
  locale_t,
}) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [expanded, setExpanded] = useState(false);
  const [formState, setFormState] = useState({
    menuName: { value: "", error: false },
    menuKey: { value: "", error: false },
  });
  const [copied, setCopied] = useState(false);
  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [responseState, setResponseState] = useState("success");
  const [showShoppingList, setShowShoppingList] = useState(false);
  const [showDayToggle, setShowDayToggle] = useState(false);
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [showRecipeForm, setShowRecipeForm] = useState(false);
  const navigate = useNavigate();
  const muiTheme = useTheme();

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  const currentMenu = menus.filter((m) => m.shareKey === shareKey)[0];
  const isDemo = shareKey === demoKey;
  useEffect(() => {
    if (currentMenu) {
      document.title = locale_t("document.title", {
        name: currentMenu.name,
        webpage: "Stellar's Menu",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenu, menus]);

  const handleShoppingList = () => {
    setShowShoppingList(!showShoppingList);
  };

  const handleChangeDay = () => {
    setShowDayToggle(!showDayToggle);
  };

  const handleAddRecipe = () => {
    setShowRecipeForm(!showRecipeForm);
  };

  const handleShowThemeModal = () => {
    setShowThemeModal(!showThemeModal);
  };

  return (
    <NavContainer>
      <Nav color={muiTheme.palette.background.default}>
        {(loggedIn || isDemo) && (
          <>
            <Navbars onClick={() => setExpanded(!expanded)} />
            <NavExpandedMenu
              initial={{ right: isDesktop ? "-40%" : "-100%" }}
              animate={{
                right: expanded ? "0%" : isDesktop ? "-40%" : "-100%",
              }}
              color={muiTheme.palette.background.navMenu}
              isDesktop={isDesktop ? "true" : "false"}
              expanded={expanded ? "true" : "false"}
            >
              {/* <Title>{locale_t("info.nav.welcomeBack")}</Title> */}
              <NavExpandedImage alt="Meal" src={logo} />
              {(currentMenu || isDemo) && (
                <>
                  <MenuText>{locale_t("info.nav.currentMenu")}</MenuText>
                  <MenuText>
                    {isDemo ? "Stellar's Choices" : currentMenu.name}
                  </MenuText>
                  <ActionButtonContainer>
                    <ActionButton
                      variant={copied ? "contained" : "outlined"}
                      startIcon={
                        <FontAwesomeIcon
                          icon={copied ? faCircleCheck : faClipboard}
                        />
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(shareKey);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 2000);
                      }}
                    >
                      {copied
                        ? locale_t("button.copied")
                        : locale_t("button.copyShareKey")}
                    </ActionButton>
                    {currentMenu && (
                      <ActionButton
                        variant={"outlined"}
                        startIcon={<FontAwesomeIcon icon={faRectangleList} />}
                        onClick={handleChangeDay}
                      >
                        {locale_t("button.changeDay")}
                      </ActionButton>
                    )}
                  </ActionButtonContainer>
                  <ActionButtonContainer>
                    <ActionButton
                      variant="outlined"
                      component={Link}
                      to={`/${shareKey}`}
                      onClick={() => setExpanded(false)}
                      startIcon={<FontAwesomeIcon icon={faCalendarDays} />}
                    >
                      {locale_t("button.viewMenu")}
                    </ActionButton>
                    {currentMenu && (
                      <ActionButton
                        variant={"outlined"}
                        startIcon={<FontAwesomeIcon icon={faRectangleList} />}
                        onClick={handleShoppingList}
                      >
                        {locale_t("button.viewShoppingList")}
                      </ActionButton>
                    )}
                  </ActionButtonContainer>
                  <ActionButtonContainer>
                    <ActionButton
                      variant="outlined"
                      component={Link}
                      to={`/recipes/${shareKey}`}
                      onClick={(e) => {
                        if (!loggedIn) {
                          e.preventDefault();
                          handleLogin({ setSnackbar, locale_t });
                        } else {
                          setExpanded(false);
                        }
                      }}
                      startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                    >
                      {locale_t("button.editRecipes")}
                    </ActionButton>
                    <ActionButton
                      variant={"outlined"}
                      startIcon={<FontAwesomeIcon icon={faRectangleList} />}
                      onClick={handleAddRecipe}
                    >
                      {locale_t("button.addRecipe")}
                    </ActionButton>
                  </ActionButtonContainer>
                </>
              )}
              <div style={{ width: "100%" }}>
                <Divider variant="middle" sx={{ margin: "1vh" }}>
                  <Chip
                    sx={{ backgroundColor: "#000000" }}
                    label={locale_t("label.chip.menus")}
                  />
                </Divider>
              </div>
              {menus.length > 0 &&
                menus.map((menu, index) => (
                  <NavButton
                    key={`menus[${index}]`}
                    label={menu.name}
                    link={`/${menu.shareKey}`}
                    onClick={() => setExpanded(false)}
                  />
                ))}
              <NavButton
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                label={locale_t("button.addMenu")}
                link={"/recipes"}
                onClick={(e) => {
                  if (!loggedIn) {
                    e.preventDefault();
                    handleLogin({ setSnackbar, locale_t });
                  } else {
                    setExpanded(false);
                  }
                }}
              />
              <SubscribeMenu
                loggedIn={loggedIn}
                setExpanded={setExpanded}
                formState={formState}
                setFormState={setFormState}
                auth={auth}
                database={database}
                setSnackbar={setSnackbar}
                navigate={navigate}
                setResponseState={setResponseState}
                locale_t={locale_t}
              />
              <div style={{ width: "100%" }}>
                <Divider variant="middle" sx={{ margin: "1vh" }}>
                  <Chip
                    sx={{ backgroundColor: "#000000" }}
                    label={locale_t("label.chip.settings")}
                  />
                </Divider>
              </div>
              <ActionButtonContainer>
                <ActionButton
                  sx={{
                    backgroundImage: "#232323",
                  }}
                  variant="outlined"
                  onClick={handleShowThemeModal}
                >
                  {locale_t("button.changeTheme")}
                </ActionButton>
              </ActionButtonContainer>
              <div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (!loggedIn) {
                      const provider = new GoogleAuthProvider();
                      signInWithGoogle(auth, provider);
                    } else {
                      setExpanded(false);
                      signOut(auth).then(() => {
                        setResponseState("success");
                        setSnackbar((prev) => [
                          ...prev,
                          {
                            message: locale_t("info.snack.signedOut"),
                            key: new Date().getTime(),
                          },
                        ]);
                      });
                    }
                  }}
                  sx={{ margin: "1vh", fontSize: "1em" }}
                >
                  {locale_t(loggedIn ? "button.logout" : "button.login")}
                </Button>
              </div>
              <div style={{ width: "100%" }}>
                <Divider variant="middle" sx={{ margin: "1vh" }}>
                  <Chip
                    sx={{ backgroundColor: "#000000" }}
                    label={locale_t("label.chip.about")}
                  />
                </Divider>
              </div>
              <ActionButtonContainer
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <NavButton
                  label={locale_t("button.privacyPolicy")}
                  link={"/privacy-policy"}
                  onClick={() => setExpanded(false)}
                />
                {loggedIn && (
                  <KofiButton
                    kofiID="stellarsmenu"
                    title="Support Us"
                    color="#323842"
                  />
                )}
              </ActionButtonContainer>
            </NavExpandedMenu>
          </>
        )}
        <MainLink to={`/${shareKey}`} onClick={() => setExpanded(false)}>
          <NavText isDesktop={isDesktop}>Stellar's </NavText>
          <NavImage isDesktop={isDesktop} src={logo} />
          <NavText isDesktop={isDesktop}> Menu</NavText>
        </MainLink>
      </Nav>
      <ListModal
        showShoppingList={showShoppingList}
        setShowShoppingList={setShowShoppingList}
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
      />
      <DayToggleModal
        showDayToggle={showDayToggle}
        setShowDayToggle={setShowDayToggle}
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
      />
      <RecipeModal
        showRecipeForm={showRecipeForm}
        setShowRecipeForm={setShowRecipeForm}
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
        currentMenu={currentMenu}
      />
      <ThemeModal
        showThemeModal={showThemeModal}
        setShowThemeModal={setShowThemeModal}
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
      />
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={responseState}
      />
    </NavContainer>
  );
};

export default Navbar;
