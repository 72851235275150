import React from "react";
import TextField from "@mui/material/TextField";
import { ref, set, get } from "firebase/database";
import { Button } from "@mui/material";

import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainText, Title } from "../Styled/Text";
import { Screen } from "../Styled/Containers";

const reloadToNewMenu = ({ navigate, shareKey, setExpanded }) => {
  navigate(`/${shareKey}/reload`);
  if (setExpanded) {
    setExpanded(false);
  }
};

export const subscribeToMenu = ({
  shareKey,
  menuName,
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  setExpanded,
  setResponseState,
  locale_t,
}) => {
  if (shareKey) {
    const menusRef = ref(database, "menus");

    get(menusRef).then((menusSnapshot) => {
      const menus = Object.values(menusSnapshot.val() || []);

      if (!menus.map((m) => m.shareKey).includes(shareKey)) {
        if (setResponseState) {
          setResponseState("warning");
        }
        setSnackbar((prev) => [
          ...prev,
          {
            message: locale_t("info.snack.invalidShareKey", {
              key: shareKey,
            }),
            key: new Date().getTime(),
          },
        ]);
      } else {
        const usersRef = ref(
          database,
          "users/" + auth.currentUser.uid + "/menus/"
        );

        get(usersRef).then((userMenusSnapshot) => {
          const userMenus = Object.values(userMenusSnapshot.val() || []);

          if (userMenus.map((m) => m.shareKey).includes(shareKey)) {
            if (setResponseState) {
              setResponseState("error");
            }
            setSnackbar((prev) => [
              ...prev,
              {
                message: locale_t("info.snack.alreadySubscribed", {
                  key: shareKey,
                }),
                key: new Date().getTime(),
              },
            ]);
          } else {
            const userMenuRef = ref(
              database,
              "users/" + auth.currentUser.uid + "/menus/" + shareKey
            );
            set(userMenuRef, {
              shareKey: shareKey,
              name: menuName,
              author: false,
            }).then(() => {
              if (setResponseState) {
                setResponseState("success");
              }
              setSnackbar((prev) => [
                ...prev,
                {
                  message: locale_t("info.snack.subscribeSuccessful", {
                    name: menuName,
                  }),
                  key: new Date().getTime(),
                },
              ]);

              setTimeout(() => {
                reloadToNewMenu({ navigate, shareKey, setExpanded });
                setFormState({
                  ...formState,
                  menuName: { error: false, value: "" },
                  menuKey: { error: false, value: "" },
                });
              }, 4000);
            });
          }
        });
      }
    });
  }
};

const SubscribableComponent = ({
  shareKey,
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  locale_t,
}) => (
  <Screen>
    <Title>{locale_t("info.subscribable.title")}</Title>
    <MainText>{locale_t("info.subscribable.description")}</MainText>
    <MainText>{locale_t("info.subscribable.subdescription")}</MainText>
    <TextField
      error={formState.menuName.error}
      sx={{ margin: "1vh" }}
      size="small"
      value={formState.menuName.value}
      label={locale_t("label.field.menuName")}
      placeholder={locale_t("label.field.menuName")}
      helperText={
        formState &&
        formState.menuName &&
        formState.menuName.error &&
        locale_t(`label.field.error.menuName`)
      }
      onChange={(event) =>
        setFormState({
          ...formState,
          menuName: { ...formState.menuName, value: event.target.value },
        })
      }
    />
    <Button
      variant="outlined"
      startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
      onClick={() => {
        if (formState) {
          const menuName = formState.menuName.value;
          if (menuName) {
            subscribeToMenu({
              shareKey,
              menuName,
              formState,
              setFormState,
              auth,
              database,
              setSnackbar,
              navigate,
            });
          } else {
            setFormState({
              ...formState,
              menuName: { ...formState.menuName, error: true },
            });
          }
        }
      }}
    >
      {locale_t("button.subscribe")}
    </Button>
  </Screen>
);

export default SubscribableComponent;
