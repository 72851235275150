const resources = {
  en: {
    locale: {
      document: {
        title: "{{name}} Menu - {{webpage}}",
      },
      button: {
        addRecipe: "Add Recipe",
        edit: "Edit",
        removeRecipe: "Remove Recipe",
        save: "Save",
        addMenu: "Add a new Menu",
        subscribe: "Subscribe",
        login: "Log in with Google",
        logout: "Log Out",
        copyShareKey: "Copy Share Key",
        copied: "Copied!",
        viewMenu: "View Menu",
        editRecipes: "Edit Recipes",
        remove: "Remove",
        changeName: "Change Name",
        rerollMenu: "Reroll Week",
        viewShoppingList: "View Shopping List",
        changeDay: "Change Day",
        rerolled: "Rerolled Week!",
        mustRequestReroll: "Request Reroll",
        rerollRequested: "Reroll Requested",
        privacyPolicy: "Privacy Policy",
        changeTheme: "Change Theme",
        viewDemo: "View a curated menu for dinners",
        closeAndAccept: "Close and Accept",
        close: "Close",
        reroll: "Reroll",
        cancelReroll: "Cancel Reroll",
        acceptReroll: "Accept Reroll",
        viewList: "View Shopping List",
        done: "Done",
        default: "Reset to Default",
      },
      theme: {
        modal: {
          title: "Change Theme",
          subtitle:
            "Use the controls below to modify Stellar's Menu's theme. Press the color buttons to change the color.",
        },
        enableGradient: "Enable Gradient",
        background: {
          title: "Background Options",
          single: "Background Color",
          top: "Background Color (Top)",
          bottom: "Background Color (Bottom)",
        },
      },
      label: {
        chip: {
          menus: "Menus",
          subscribe: "Subscribe to a Menu",
          settings: "Settings",
          about: "About",
          menuEntries: "Menu Entries",
        },
        field: {
          error: {
            menuKey: "Please provide a share key",
            menuName: "Please provide a menu name",
          },
          helperText: {
            ingredients: "Each ingredient must be entered on a new line",
            instructions:
              "Each instruction must be entered on a new line and ordered in chronological order",
          },
          menuKey: "Share Key",
          menuName: "Menu Name",
          name: "Name",
          info: "Info",
          imageSource: "Image Source",
          timeToCook: "Time to Cook",
          servingSize: "Serving Size",
          caloriesPerServing: "Calories Per Serving",
          ingredients: "Ingredients",
          instructions: "Instructions",
          randomize: "Randomize Entries per week",
        },
        imageAlt: {
          meal: "Meal",
        },
      },
      info: {
        nav: {
          welcomeBack: "Welcome Back!",
          currentMenu: "Current Menu:",
        },
        viewMenu: {
          title: "Oh hi!",
          description: "Welcome back!",
          subdescription:
            "View your menus by pressing the icon in the top right corner.",
        },
        login: {
          title: "Hello!",
          description: "You must be new here.",
          subdescription: "If not, welcome back!",
        },
        noMenus: {
          title: "Oh hi!",
          description:
            "Please press the icon in the top right corner to begin.",
        },
        noRecipes: {
          title: "Uh oh!",
          description:
            "You don't seem to have any recipes for your '{{name}}' menu. Feel free to add one!",
          subscribableDescription:
            "You are currently viewing a menu you subscribed to that does not have any menus.",
          subscribableSubdescription:
            "Please come back later when the author updates '{{name}}'!",
        },
        subscribable: {
          title: "Not so fast!",
          description:
            "You tried to access a menu, but you have not subscribed to it.",
          subdescription: "Please subscribe to this menu to view!",
        },
        changeName: {
          title: "Current Menu: {{name}}",
          description:
            "You are currently subscribed to a menu you named '{{name}}'. You may change the name of the menu you subscribed to.",
        },
        modal: {
          recipeSave:
            "Are you sure you want to navigate away? Be sure to save if you have not already!",
        },
        snack: {
          createdMenu:
            "Successfully created your 'asdf' menu! View it in the sidebar.",
          updatedRecipe: "Successfully updated recipe!",
          alreadySubscribed:
            "You are already subscribed to the shareKey {{key}}",
          signedOut: "Successfully signed out!",
          recipeUpdated:
            "Successfully {{state}} your '{{menuName}}' menu! View it in the sidebar.",
          updatedName:
            "Successfully changed '{{previousName}}' menu name to '{{currentName}}'! Reloading to display...",
          subscribeSuccessful:
            "Successfully subscribed to '{{name}}' menu! Reloading to display...",
          invalidShareKey:
            "We did not find any menus with the following shareKey: {{key}}. Please retry with another shareKey.",
          rerollSuccessful:
            "Successfully refreshed the week for '{{name}}' menu! Reloading to display...",
          rerollRequested:
            "Successfully requested a reroll for the '{{name}}' menu!",
          pleaseSignIn: "Please sign in first and try again!",
        },
        menuWizard: {
          noSubscription: {
            title: "Purchase Pro Subscription",
          },
          title: "A new menu appears!",
          subtitle:
            "Your new menu is ready. Feel free to reroll your menu or  mark some days as  leftovers or dine-in.",
          listTitle: "Shopping List",
          listSubtitle:
            "Use this list to help you shop for the week.<br/><br/><strong>NOTE:</strong> It is always a good idea to buy more than required to ensure you have enough ingredients for all meals.",
          dayToggleTitle: "Change Day",
          dayToggleSubtitle:
            "Tells Stellar's Menu when to generate this menu for the week.<br/><strong>NOTE:</strong> Generation will occur on the next upcoming day.",
          dayNotes: {
            sunday:
              "Generate for the week on a Sunday.<br/>Stellar's Choice (TM).",
            monday: "Generate for the week on a Monday.<br/>Mondays, amirite?",
            tuesday:
              "Generate for the week on a Tuesday.<br/>Week's in full swing, might as well run all errands.",
            wednesday:
              "Generate for the week on a Wednesday.<br/>At least there's only a few more days of the week.",
            thursday:
              "Generate for the week on a Thursday.<br/>Save some few cycles on the weekend.",
            friday:
              "Generate for the week on a Friday.<br/>Only if it works for you!",
            saturday:
              "Generate for the week on a Saturday.<br/>Shop and prep for the coming week.",
          },
          subsubtitle: "Your New Menu",
          recipeTitle: "Add Recipe to '{{menu}}' Menu",
          recipeSubtitle: "Found a good recipe to add?",
        },
        subscriptionTable: {
          rationale:
            "Stellar's Menu uses AI to generate shopping lists and recipes.<br/><br/>Consider supporting us to add new features, such as an explore page to find new recipes, the pantry which includes recipe selection based on pantry ingredients, and many more to come!",
          tier: "Tier",
          price: "Price",
          basicPlan: "Basic",
          basicPrice: "Free",
          proPlan: "Pro",
          proPrice: "$1.99/mo",
          premiumPlan: "Premium",
          premiumPrice: "3.99 - 19.99/mo (user choice)",
          feature1: "Notifications",
          feature2: "Shopping Lists",
          feature3: "Recipe Generation",
          footnote:
            "Aforementioned services may be applied to existing tiers. Rates may change, with an express written warning 1 month prior to rate changes. You may cancel anytime by visiting TODO. Thanks for supporting us!",
        },
      },
    },
  },
};

export default resources;
