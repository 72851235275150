import React from "react";
import styled from "styled-components";

const Rotator = styled.div(({ rotationAngle, distance, size }) => {
  const transform = `rotate(${rotationAngle}deg)`;
  return {
    position: "absolute",
    transformOrigin: "center center",
    transform,
  };
});

export const RotatingComponent = ({ __rotation, size, children }) => {
  return (
    <Rotator rotationAngle={__rotation} size={size}>
      {children(size)}
    </Rotator>
  );
};
