import { configureStore } from "@reduxjs/toolkit";
import recipesReducer from "../Routes/Recipes/recipesSlice";
import { reducer as formReducer } from "redux-form";

export const store = configureStore({
  reducer: {
    data: recipesReducer,
    form: formReducer,
  },
});
