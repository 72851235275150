import { createSlice } from "@reduxjs/toolkit";

const recipesSlice = createSlice({
  name: "data",
  initialState: {},
  reducers: {
    recipesUpdated(state, action) {
      state.menu = { ...action.payload };
    },
  },
});

export const { recipesUpdated } = recipesSlice.actions;

export default recipesSlice.reducer;
