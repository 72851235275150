import { Divider } from "@mui/material";
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import "../../App.css";
import { DOW } from "../../assets/util";
import { SecondaryText, SubText } from "../Styled/Text";

const MealConfig = ({ data, rerollRequested, rerollData }) => {
  const weekConfig = DOW.map((day, index) => ({ day, meal: data[day] }));
  const rerollConfig =
    !!rerollData &&
    DOW.map((day) => ({
      day,
      meal: rerollData[day],
    }));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "show",
      }}
    >
      {weekConfig.map((day, index) => (
        <div key={`weekconfig[${index}]`}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: 200 }}>
              <Divider />
              <div
                style={{
                  margin: "0.5vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SecondaryText>{day.day}</SecondaryText>
                {day.meal && day.meal.image && (
                  <img
                    style={{
                      width: "10vh",
                      height: "10vh",
                      borderRadius: "50%",
                    }}
                    alt="Meal"
                    src={day.meal?.image}
                  />
                )}
                <SubText style={{ textAlign: "center" }}>
                  {day.meal.name}
                </SubText>
              </div>
            </div>
            {rerollRequested && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{}}>
                  <FaChevronRight />
                </div>
                <div style={{ width: 200 }}>
                  <Divider />
                  <div
                    style={{
                      margin: "0.5vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <SecondaryText>Rerolled</SecondaryText>
                    {rerollConfig[index].meal &&
                      rerollConfig[index].meal.image && (
                        <img
                          style={{
                            width: "10vh",
                            height: "10vh",
                            borderRadius: "50%",
                          }}
                          alt="Meal"
                          src={rerollConfig[index].meal?.image}
                        />
                      )}
                    <SubText style={{ textAlign: "center" }}>
                      {rerollConfig[index].meal.name}
                    </SubText>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MealConfig;
