import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { get, ref, set } from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import "../../App.css";
import { getNextGeneration } from "../../assets/util";
import { SubText } from "../Styled/Text";
import DayToggle from "./DayToggle";

// TODO Implement shopping list into the metadata of the shareKey.
// TODO Consider writing the shopping list myself using open source
// tools online.
// Katie Zhang's medium article explains in a great way how to do this:
//    https://kzz.medium.com/how-i-automated-the-creation-of-my-grocery-list-from-a-bunch-of-recipe-websites-with-python-90d15e5c0c83
//
// Further, Zack Schollz has a library for extracting ingredient lists from any given recipe online:
//    https://schollz.com/tinker/ingredients/
//
// I should look to expand upon Zack Schollz's library and see if I can add a manual list of ingredients
//

const ToggleContent = ({ selected, setSelected, locale_t }) => {
  return (
    <DayToggle
      selected={selected}
      setSelected={setSelected}
      locale_t={locale_t}
    />
  );
};

const DayToggleModal = ({
  showDayToggle,
  setShowDayToggle,
  auth,
  database,
  shareKey,
  locale_t,
}) => {
  const [selected, setSelected] = useState();
  const [menuData, setMenuData] = useState({});
  const menuRef = useMemo(() => {
    if (!!database && !!auth?.currentUser?.uid && !!shareKey) {
      return ref(
        database,
        "users/" + auth.currentUser.uid + "/menus/" + shareKey
      );
    } else {
      return undefined;
    }
  }, [auth?.currentUser?.uid, database, shareKey]);

  useEffect(() => {
    const fetchData = async () => {
      if (!!menuRef) {
        const data = (await get(menuRef)).val();
        if (!!data) {
          setSelected(data.dayGeneration);
          setMenuData(data);
        }
      }
    };

    fetchData();
  }, [database, shareKey, auth?.currentUser?.uid, menuRef]);

  const handleDone = () => {
    setShowDayToggle(false);
  };

  const handleSelected = useCallback(
    (dayGeneration) => {
      const newMenuData = { ...menuData, dayGeneration };

      set(menuRef, newMenuData).then(() => {
        setSelected(dayGeneration);

        const weekRef = ref(database, "menus/" + shareKey + "/MOTW");
        get(weekRef).then((snapshot) => {
          const data = snapshot.val();
          set(weekRef, { ...data, until: getNextGeneration(dayGeneration) });
        });
      });
    },
    [database, menuData, menuRef, shareKey]
  );

  return (
    <Dialog open={showDayToggle}>
      <DialogTitle>
        <p>{locale_t("info.menuWizard.dayToggleTitle")}</p>
        <SubText style={{ fontSize: "1rem", margin: 0 }}>
          <Trans>{locale_t("info.menuWizard.dayToggleSubtitle")}</Trans>
        </SubText>
      </DialogTitle>
      <ToggleContent
        locale_t={locale_t}
        selected={selected}
        setSelected={handleSelected}
      />
      <DialogActions>
        <Button onClick={handleDone} color="primary">
          {locale_t("button.done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DayToggleModal;
