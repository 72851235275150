import { faRectangleList } from "@fortawesome/free-regular-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Collapse, TextField } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { default as React, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import styledComp from "styled-components";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../App.css";
import { ActionButton } from "../../Components/Navbar/NavbarElements";
import { MainText, Title } from "../../Components/Styled/Text";
const RecipeImage = styledComp.img`
  border-radius: 4px;
  width: 200px;
  height: 200px;
  object-fit: cover;
`;

const ImagePlaceholder = styledComp.div`
  width: 200px;
  height: 200px;
`;

const InternalScreen = styledComp.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 4;
`;

const RecipeInfo = styledComp.p`
  font-size: 18px;
`;

const paperBackground = {
  backgroundColor: "#121212",
  backgroundImage:
    "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
};

export const RecipeCard = ({
  recipe,
  locale_t,
  handleEditRecipe,
  handleDeleteRecipe,
}) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Card
      elevation={isHovered ? 3 : 1}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width: "200px",
        height: "auto",
        alignSelf: "center",
        position: "relative",
      }}
    >
      <Box
        style={{
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <Collapse
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            ...paperBackground,
          }}
          in={isHovered || !isDesktop || !recipe?.image}
          timeout="auto"
          unmountOnExit
        >
          <RecipeInfo>{recipe.name}</RecipeInfo>
        </Collapse>
        {recipe?.image ? (
          <RecipeImage alt={recipe.name} src={recipe.image} />
        ) : (
          <ImagePlaceholder />
        )}
        <Box
          flexDirection={"row"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginX={"16px"}
          height={"200"}
        >
          <ActionButton
            style={{ visibility: isHovered ? "visible" : "hidden" }}
            variant={"outlined"}
            size="small"
            startIcon={<FontAwesomeIcon icon={faRectangleList} />}
            onClick={() => handleEditRecipe(recipe.id)}
          >
            {locale_t("button.edit")}
          </ActionButton>
          {/* <ActionButton
            variant={"outlined"}
            startIcon={<FontAwesomeIcon icon={faRectangleList} />}
            onClick={handleDeleteRecipe}
          >
            {locale_t("button.removeRecipe")}
          </ActionButton> */}
        </Box>
      </Box>
    </Card>
  );
};

export const RenderTextField = ({
  label,
  input,
  isDesktop,
  value,
  setValue,
}) => (
  <TextField
    sx={{ width: isDesktop ? 400 : "100%" }}
    margin="dense"
    label={label}
    placeholder={label}
    {...input}
    value={value}
    onChange={(event) => setValue(event.target.value)}
  />
);

export const NeedsMealsComponent = ({
  currentMenu,
  shareKey,
  locale_t,
  needsNavigate,
}) => {
  const description =
    currentMenu &&
    (currentMenu.author
      ? locale_t("info.noRecipes.description", { name: currentMenu.name })
      : locale_t("info.noRecipes.subscribableDescription"));

  return (
    <>
      {currentMenu?.name && (
        <InternalScreen>
          <Title>{locale_t("info.noRecipes.title")}</Title>
          {currentMenu && <MainText>{description}</MainText>}
          {description ===
            locale_t("info.noRecipes.subscribableDescription") && (
            <MainText>
              {locale_t("info.noRecipes.subscribableSubdescription", {
                name: currentMenu.name,
              })}
            </MainText>
          )}
          {currentMenu?.author && needsNavigate && (
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              component={Link}
              to={`/recipes/${shareKey}`}
            >
              {locale_t("button.addRecipe")}
            </Button>
          )}
        </InternalScreen>
      )}
    </>
  );
};
