import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMui } from "@mui/system";
import { Button } from "@mui/material";
import { motion } from "framer-motion";

export const ActionButton = styledMui(Button)({
  marginTop: "1vh",
  marginBottom: "1vh",
  marginLeft: "1vh",
  marginRight: "1vh",
  fontSize: "1em",
  flex: 1,
});

export const ActionButtonContainer = styled.div(() => ({
  display: "flex",
  width: "80%",
}));

export const NavContainer = styled.div(() => ({
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: 12,
}));

export const MenuText = styled.p(() => ({
  fontSize: "1.5em",
  fontFamily: "Comfortaa-Bold",
  margin: "1vh",
}));

export const NavExtendedImage = styled.img(() => ({
  width: "33vh",
  height: "33vh",
  borderRadius: "50%",
}));

export const NavExpandedMenu = styled(motion.div)(
  ({ color, isDesktop, expanded }) => ({
    height: "calc(100vh - 60px)",
    overflow: "auto",
    marginBottom: "60vh",
    right: "0%",
    display: expanded === "true" ? "flex" : "flex",
    position: "absolute",
    top: "60px",
    flexDirection: "column",
    alignItems: "center",
    width: isDesktop === "true" ? "40%" : "100%",
    backgroundColor: color,
    color: "white",
    zIndex: 16,
  })
);

export const NavImage = styled.img(() => ({
  width: "32px",
  height: "32px",
}));

export const NavText = styled.p(({ isDesktop }) => ({
  fontFamily: "Comfortaa-Bold",
  fontSize: isDesktop ? "1.5em" : "1em",
  color: "white",
}));

export const Navbars = styled(FaBars)(() => ({
  color: "#ffffff",
  display: "block",
  position: "absolute",
  top: 0,
  right: 0,
  transform: "translate(-100%, 75%)",
  fontSize: "1.8rem",
  cursor: "pointer",
}));

export const MainLink = styled(Link)`
  color: #808080;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #000000;
  }
`;

export const Nav = styled.nav(({ color }) => ({
  background: color,
  height: "60px",
  display: "flex",
  justifyContent: "center",
  padding: "0.2rem calc((100vw - 1000px) / 2)",
  zIndex: 12,
}));
