import React from "react";

import { GridLoader } from "react-spinners";
import "../../App.css";
import { MainText } from "../Styled/Text";
import { Screen } from "../Styled/Containers";

const LoadingScreen = () => (
  <Screen>
    <GridLoader color={"white"} />
    <MainText>Loading...</MainText>
  </Screen>
);

export default LoadingScreen;
