import React from "react";
import "../../App.css";
import MUISnackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/bundle";
import "swiper/css/navigation";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({ open, setOpen, messageInfo, setMessageInfo, severity }) => (
  <MUISnackbar
    key={messageInfo ? messageInfo.key : undefined}
    open={open}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
    autoHideDuration={4000}
    onClose={() => {
      setOpen(false);
    }}
    TransitionProps={{
      onExited: () => {
        setMessageInfo(undefined);
      },
    }}
  >
    {messageInfo && messageInfo.message && (
      <Alert severity={severity}>{messageInfo.message}</Alert>
    )}
  </MUISnackbar>
);

export default Snackbar;
