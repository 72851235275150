import useMediaQuery from "@mui/material/useMediaQuery";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useCallback, useEffect } from "react";
import Particles from "react-particles";
import { useParams } from "react-router-dom";
import { loadFull } from "tsparticles";
import Demo from "../../Components/Demo";
import LoginContainer from "../../Components/Login";
import MealSwiper from "../../Components/MealSwiper";
import ParticleConfig from "../../assets/particle-config.json";
import { demoKey, getMenus } from "../../assets/util";
import "../../styles.css";

export const signInWithGoogle = (auth, provider) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
      // // The signed-in user info.
      // const user = result.user;
      // // ...
    })
    .catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // The email of the user's account used.
      // const email = error.customData.email;
      // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

export const Home = ({
  setShareKey,
  database,
  loggedIn,
  setLoggedIn,
  auth,
  loading,
  setLoading,
  setMenus,
  locale_t,
}) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  const provider = new GoogleAuthProvider();

  const { shareKey, reload } = useParams();
  const isDemo = shareKey === demoKey;
  useEffect(() => {
    if (setShareKey) {
      setShareKey(shareKey);
      if (auth && auth.currentUser) {
        getMenus({ database, user: auth.currentUser, setMenus, setLoading });
      } else {
        setMenus([]);
      }
    }
  }, [
    shareKey,
    reload,
    auth,
    auth.currentUser,
    setShareKey,
    database,
    setMenus,
    setLoading,
  ]);

  return (
    <div
      style={{
        position: "absolute",
        top: "60px",
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={ParticleConfig}
      />
      {loggedIn ? (
        <MealSwiper
          reload={reload}
          shareKey={shareKey}
          auth={auth}
          database={database}
          isDesktop={isDesktop}
          loading={loading}
          setLoading={setLoading}
          locale_t={locale_t}
        />
      ) : isDemo ? (
        <Demo
          reload={reload}
          shareKey={shareKey}
          auth={auth}
          database={database}
          isDesktop={isDesktop}
          loading={loading}
          setLoading={setLoading}
          locale_t={locale_t}
        />
      ) : (
        <LoginContainer
          locale_t={locale_t}
          onLogin={() => signInWithGoogle(auth, provider)}
        />
      )}
    </div>
  );
};

export default Home;
