const validate = (values) => {
  const errors = {};
  if (!values.menu) {
    errors.menu = "Required";
  }
  // if (!values.members || !values.members.length) {
  //   errors.members = { _error: "At least one member must be entered" };
  // } else {
  //   const membersArrayErrors = [];
  //   values.members.forEach((member, memberIndex) => {
  //     const memberErrors = {};
  //     if (!member || !member.firstName) {
  //       memberErrors.firstName = "Required";
  //       membersArrayErrors[memberIndex] = memberErrors;
  //     }
  //     if (!member || !member.lastName) {
  //       memberErrors.lastName = "Required";
  //       membersArrayErrors[memberIndex] = memberErrors;
  //     }
  //     if (member && member.hobbies && member.hobbies.length) {
  //       const hobbyArrayErrors = [];
  //       member.hobbies.forEach((hobby, hobbyIndex) => {
  //         if (!hobby || !hobby.length) {
  //           hobbyArrayErrors[hobbyIndex] = "Required";
  //         }
  //       });
  //       if (hobbyArrayErrors.length) {
  //         memberErrors.hobbies = hobbyArrayErrors;
  //         membersArrayErrors[memberIndex] = memberErrors;
  //       }
  //       if (member.hobbies.length > 5) {
  //         if (!memberErrors.hobbies) {
  //           memberErrors.hobbies = [];
  //         }
  //         memberErrors.hobbies._error = "No more than five hobbies allowed";
  //         membersArrayErrors[memberIndex] = memberErrors;
  //       }
  //     }
  //   });
  //   if (membersArrayErrors.length) {
  //     errors.members = membersArrayErrors;
  //   }
  // }
  return errors;
};

export default validate;
