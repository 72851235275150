import React from "react";
import "../../App.css";
import { Button } from "@mui/material";
import { MainText, Title } from "../Styled/Text";
import { Screen } from "../Styled/Containers";
import { demoKey } from "../../assets/util";
import { Link } from "react-router-dom";

const Login = ({ locale_t, onLogin }) => (
  <Screen>
    <Title>{locale_t("info.login.title")}</Title>
    <MainText>{locale_t("info.login.description")}</MainText>
    <MainText>{locale_t("info.login.subdescription")}</MainText>
    <Button variant={"outlined"} onClick={onLogin}>
      {locale_t("button.login")}
    </Button>
    <MainText>- or -</MainText>
    <Button variant={"outlined"} component={Link} to={`/${demoKey}`}>
      {locale_t("button.viewDemo")}
    </Button>
  </Screen>
);

export default Login;
