import styled from "styled-components";

export const Screen = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 4;
`;
