export const getAllIngredients = (menuData) => {
  let values = [];

  // Helper function to recursively traverse the object
  function traverse(menuData) {
    for (let prop in menuData) {
      if (menuData.hasOwnProperty(prop)) {
        if (prop === "ingredients") {
          // If the current property matches the specified key, add its value to the array
          values.push(menuData[prop]);
        } else if (typeof menuData[prop] === "object") {
          // If the current property is an object, recursively traverse it
          traverse(menuData[prop]);
        }
      }
    }
  }

  // Start the traversal from the top-level object
  traverse(menuData);

  const allIngredients = values.reduce((prev, curr) => [...prev, ...curr], []);

  return allIngredients;
};

// ingredients looks something like:
// const ingredients = [
//   "1 pound ground chicken",
//   "1 large egg",
//   "1/2 cup Panko breadcrumbs",
//   "1/2 cup Parmesan cheese grated",
//   "2 tablespoons olive oil",
//   "1 teaspoon garlic powder",
//   "1 teaspoon onion powder",
//   "1 teaspoon smoked paprika",
//   "brown jasmine rice, for serving",
//   "milk, 2 cups",
//   "sugar 1/4 cup",
//   "water: 3/4 cup"
// ];
// NOTE that we currently will format 'sugar 1/4 cup' as 'sugar 1/4 cup' until we update/modify the regex or find another string comparison solution.`

// export const createShoppingList = (ingredients) => {
//   const shoppingList = [];
//   const quantityUnitItemRegex = /(?:(\d+(?: \/ \d+)?)\s+)?(?:(.*?)\s+)?(.*)/;
//   const quantityUnitRegex = /^\s*\d.*\s.*$/;

//   ingredients.forEach((ingredient) => {
//     // remove anything after 'for'
//     if (ingredient.includes("for")) {
//       ingredient = ingredient.split("for")[0];
//     }

//     // handle 'item , quantity unit' and 'item : quantity unit'
//     if (ingredient.includes(",") || ingredient.includes(":")) {
//       const splitted = ingredient.split(/,|:/);
//       if (quantityUnitRegex.test(splitted[1])) {
//         ingredient = splitted.reverse().join(" ");
//       } else {
//         ingredient = splitted[0];
//       }
//     }

//     if (ingredient.indexOf(" ") === 0) {
//       ingredient = ingredient.substring(1);
//     }

//     if (ingredient) {
//       let quantityUnit = "";
//       let item = "";
//       console.log(ingredient);
//       const regexMatch = ingredient.match(quantityUnitItemRegex);

//       // handle 'quantity unit item'
//       if (regexMatch && (regexMatch[1] || regexMatch[2])) {
//         const words = ingredient.trim().split(" ");

//         if (regexMatch[1]) quantityUnit += `${regexMatch[1]} `;
//         if (regexMatch[2]) quantityUnit += regexMatch[2];
//         item = regexMatch[3].trim();
//       }
//       // handle 'item quantity unit'
//       else {
//         const words = ingredient.trim().split(" ");
//         const unit = words.pop();
//         const quantity = words.pop();
//         item = words.join(" ");
//         quantityUnit = quantity ? `${quantity} ${unit}` : unit;
//       }

//       shoppingList.push(`${quantityUnit} ${item}`);
//     }
//   });

//   return shoppingList;
// };

// export const createShoppingList = (ingredients) => {
//   const shoppingList = [];
//   const quantityUnitItemRegex = /(?:(\d+(?: \/ \d+)?)\s+)?(?:(.*?)\s+)?(.*)/;
//   const quantityUnitRegex = /^\s*\d.*\s.*$/;

//   ingredients.forEach((ingredient) => {
//     // remove anything after 'for'
//     if (ingredient.includes("for")) {
//       ingredient = ingredient.split("for")[0];
//     }

//     // handle 'item , quantity unit' and 'item : quantity unit'
//     if (ingredient.includes(",") || ingredient.includes(":")) {
//       const splitted = ingredient.split(/,|:/);
//       if (quantityUnitRegex.test(splitted[1])) {
//         ingredient = splitted.reverse().join(" ");
//       } else {
//         ingredient = splitted[0];
//       }
//     }

//     if (ingredient.indexOf(" ") === 0) {
//       ingredient = ingredient.substring(1);
//     }

//     if (ingredient) {
//       let quantityUnit = "";
//       let item = "";
//       console.log(ingredient);
//       const regexMatch = ingredient.match(quantityUnitItemRegex);

//       // handle 'quantity unit item'
//       if (regexMatch && (regexMatch[1] || regexMatch[2])) {
//         const words = ingredient.trim().split(" ");

//         if (regexMatch[1]) quantityUnit += `${regexMatch[1]} `;
//         if (regexMatch[2]) quantityUnit += regexMatch[2];
//         item = regexMatch[3].trim();
//       }
//       // handle 'item quantity unit'
//       else {
//         const words = ingredient.trim().split(" ");
//         const unit = words.pop();
//         const quantity = words.pop();
//         item = words.join(" ");
//         quantityUnit = quantity ? `${quantity} ${unit}` : unit;
//       }

//       const ingredientObj = {
//         item: item,
//         quantity: quantityUnit.split(" ")[0],
//         unit: quantityUnit.split(" ")[1],
//       };

//       shoppingList.push(ingredientObj);
//     }
//   });

//   console.log(shoppingList);
//   return shoppingList;
// };

const UNIT_ENTRIES = {
  tablespoon: ["tablespoon", "tablespoons", "tbsp", "tbsps"],
  teaspoon: ["teaspoon", "teaspoons", "tsp", "tsps"],
  cup: ["cup", "cups", "c"],
  pound: ["pound", "pounds", "lb", "lbs"],
  liter: ["liter", "liters", "litre", "litres", "l"],
  milliliter: ["milliliter", "millilitre", "milliliters", "millilitres", "ml"],
};

const getBaseUnit = (unit) => {
  if (unit) {
    const lowerCaseUnit = unit.toLowerCase();

    for (const [baseUnit, entries] of Object.entries(UNIT_ENTRIES)) {
      if (entries.includes(lowerCaseUnit)) {
        return baseUnit;
      }
    }

    return unit;
  } else {
    return undefined;
  }
};

export const createShoppingList = (ingredients) => {
  const shoppingList = [];
  const quantityUnitItemRegex =
    /(?:(\d+(?: \/ \d+|.\d+)?)\s+)?(?:(.*?)\s+)?(.*)/;
  const quantityUnitRegex = /^\s*\d.*\s.*$/;
  const uniqueIngredients = {};

  const convertUnits = (oldUnit, newUnit, quantity1, quantity2) => {
    const conversions = {
      teaspoon: 1,
      tablespoon: 3,
      milliliter: 4.92892,
      cup: 48,
      pound: 96,
      liter: 202.884,
      // Add more conversions as needed
    };

    const oldUnitBase = getBaseUnit(oldUnit);
    const newUnitBase = getBaseUnit(newUnit);

    const unit =
      !!conversions[oldUnitBase] && !!conversions[newUnitBase]
        ? Object.keys(conversions).find(
            (key) =>
              conversions[key] ===
              Math.max(conversions[oldUnitBase], conversions[newUnitBase])
          )
        : oldUnit;

    const convertedQuantity1 = Math.round(
      quantity1 * (conversions[oldUnitBase] ?? 1)
    );
    const convertedQuantity2 = Math.round(
      quantity2 * (conversions[newUnitBase] ?? 1)
    );
    return {
      quantity: !!conversions[unit]
        ? (convertedQuantity1 + convertedQuantity2) / conversions[unit]
        : convertedQuantity1 + convertedQuantity2,
      unit,
    };
  };

  ingredients.forEach((ingredient) => {
    // remove anything after 'for'
    if (ingredient.includes("for")) {
      ingredient = ingredient.split("for")[0];
    }

    // handle 'item , quantity unit' and 'item : quantity unit'
    if (ingredient.includes(",") || ingredient.includes(":")) {
      const splitted = ingredient.split(/,|:/);
      if (quantityUnitRegex.test(splitted[1])) {
        ingredient = splitted.reverse().join(" ");
      } else {
        ingredient = splitted[0];
      }
    }

    if (ingredient.indexOf(" ") === 0) {
      ingredient = ingredient.substring(1);
    }

    if (ingredient) {
      let quantityUnit = "";
      let item = "";
      const regexMatch = ingredient.match(quantityUnitItemRegex);

      // handle 'quantity unit item'
      if (regexMatch && (regexMatch[1] || regexMatch[2])) {
        if (regexMatch[1]) quantityUnit += `${regexMatch[1]} `;
        if (regexMatch[2]) quantityUnit += regexMatch[2];
        item = regexMatch[3].trim();
      }
      // handle 'item quantity unit'
      else {
        const words = ingredient.trim().split(" ");
        const unit = words.pop();
        const quantity = words.pop();
        item = words.join(" ");
        quantityUnit = quantity ? `${quantity} ${unit}` : unit;
      }

      let [quantity, unit] = quantityUnit.split(" ");

      quantity = quantity.includes("/")
        ? quantity.split("/")[0] / quantity.split("/")[1]
        : quantity;

      const ingredientObj = {
        item: item,
        quantity,
        unit,
      };

      // Check if ingredient already exists in the list
      const key = `${ingredientObj.item},${ingredientObj.unit}`;
      if (uniqueIngredients[key]) {
        // Add together duplicate ingredient
        const existingIngredient = uniqueIngredients[key];
        const { quantity: convertedQuantity, unit: adoptedUnit } = convertUnits(
          existingIngredient.unit,
          ingredientObj.unit,
          parseFloat(existingIngredient.quantity),
          parseFloat(ingredientObj.quantity)
        );

        // Update quantity of existing ingredient
        existingIngredient.quantity = Math.round(convertedQuantity);
        existingIngredient.unit = adoptedUnit;
      } else {
        uniqueIngredients[key] = ingredientObj;
      }
    }
  });

  for (const key in uniqueIngredients) {
    const { quantity, unit, item } = uniqueIngredients[key];
    if (
      !!unit &&
      (unit === "teaspoon" || unit === "tablespoon" || unit === "milliliter")
    ) {
      shoppingList.push(`${item}`);
    } else {
      shoppingList.push(
        `${!!quantity ? quantity : ""} ${!!unit ? unit : ""}${
          quantity > 1 ? "s" : ""
        } ${item}`
      );
    }
  }

  return shoppingList;
};
