import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import "./styles.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
// import { useMediaQuery } from "react-responsive";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import LoadingScreen from "./Components/Loading";
import Navbar from "./Components/Navbar";
import PrivacyPolicy from "./Components/Privacy-Policy";
import Home from "./Routes/Home/index";
import RecipesForm from "./Routes/Recipes";
import Recipes from "./Routes/Recipes/Recipes";
import { getMenus } from "./assets/util";
import { store } from "./redux/store";

//TODO: Verify all exhaustive deps.
// eslint-disable-next-line react-hooks/exhaustive-deps

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDyGVYfFLErMgopQyb2PsTiUhVyifi9Ino",
  authDomain: "stellars-menu.firebaseapp.com",
  databaseURL: "https://stellars-menu-default-rtdb.firebaseio.com",
  projectId: "stellars-menu",
  storageBucket: "stellars-menu.appspot.com",
  messagingSenderId: "1094614831223",
  appId: "1:1094614831223:web:9ed7cf3f04aff70ddaf672",
  measurementId: "G-ZQNRE6HF21",
};

// const MaterialTheme = createTheme({
//   legacy: false,
//   palette: {
//     mode: "dark",
//     primary: {
//       main: grey[50],
//       swiper: grey[50],
//     },
//     background: {
//       default: "#222222",
//       navMenu: "#222222",
//       recipeHeader: "#222222",
//       recipeFooter: "#222222",
//     },
//   },
//   typography: {
//     fontFamily: "Comfortaa-Bold",
//   },
// });

// const LegacyTheme = createTheme({
//   legacy: true,
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: {
//         body: {
//           backgroundImage: "linear-gradient(#000000, #1a0800) !important",
//           backgroundRepeat: "no-repeat",
//           backgroundAttachment: "fixed",
//         },
//       },
//     },
//   },
//   palette: {
//     mode: "dark",
//     primary: {
//       main: grey[50],
//       swiper: "#d04000",
//     },
//     background: {
//       default: "#000000",
//       navMenu: "#222222",
//       recipeHeader: "#000000",
//       recipeFooter: "#1a0800",
//     },
//   },
//   typography: {
//     fontFamily: "Comfortaa-Bold",
//   },
// });

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export const App = () => {
  const { t: locale_t } = useTranslation("locale");
  const auth = getAuth();
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const [savedTheme, setSavedTheme] = useState();

  const theme = useMemo(() => {
    return createTheme({
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              backgroundImage: !savedTheme
                ? "#222222"
                : `linear-gradient(${
                    (savedTheme?.backgroundColors || [])[0]
                  }, ${
                    (savedTheme?.backgroundColors || [])[
                      savedTheme?.isGradient ? 1 : 0
                    ]
                  }) !important`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            },
          },
        },
      },
      palette: {
        mode: "dark",
        primary: {
          main: grey[50],
          swiper: grey[50],
        },
        background: {
          default:
            !savedTheme || savedTheme?.topBarVisible ? "#222222" : "#00000000",
          navMenu: "#222222",
          recipeHeader: "#222222",
          recipeFooter: "#222222",
        },
      },
      typography: {
        fontFamily: "Comfortaa-Bold",
      },
    });
  }, [savedTheme]);

  const monitorThemeChanges = useCallback((user) => {
    onValue(
      ref(database, "users/" + user.uid + "/theme/galactic"),
      (snapshot) => {
        const theme = snapshot.val();
        setSavedTheme(theme);
      }
    );
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setLoggedIn(true);

        // start listening to theme.

        monitorThemeChanges(user);
      } else {
        // User is signed out.
        setLoggedIn(false);
      }
      setLoading(false);
    });
    auth.languageCode = "it";

    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    getMenus({ user: auth.currentUser, database, setMenus, setLoading });
  }, [auth, auth.currentUser]);

  const [loggedIn, setLoggedIn] = useState(auth.currentUser);
  const [shareKey, setShareKey] = useState(null);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Navbar
            store={store}
            database={database}
            auth={auth}
            expanded={navExpanded}
            setExpanded={setNavExpanded}
            menus={menus}
            shareKey={shareKey}
            loading={loading}
            setLoading={setLoading}
            loggedIn={loggedIn}
            locale_t={locale_t}
          />
          {loading && <LoadingScreen locale_t={locale_t} />}
          <div style={{ visibility: loading ? "hidden" : "visible" }}>
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    database={database}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    auth={auth}
                    loading={loading}
                    setLoading={setLoading}
                    setMenus={setMenus}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/:shareKey"
                element={
                  <Home
                    setShareKey={setShareKey}
                    database={database}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    auth={auth}
                    loading={loading}
                    setLoading={setLoading}
                    setMenus={setMenus}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/:shareKey/:reload"
                element={
                  <Home
                    setShareKey={setShareKey}
                    database={database}
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    auth={auth}
                    loading={loading}
                    setLoading={setLoading}
                    setMenus={setMenus}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/recipes"
                element={
                  <Recipes
                    database={database}
                    auth={auth}
                    onSave={() => {
                      getMenus({
                        user: auth.currentUser,
                        database,
                        setMenus,
                        setLoading,
                      });
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/recipes/:shareKey"
                element={
                  <Recipes
                    database={database}
                    auth={auth}
                    onSave={() => {
                      getMenus({
                        user: auth.currentUser,
                        database,
                        setMenus,
                        setLoading,
                      });
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/update"
                element={
                  <RecipesForm
                    database={database}
                    auth={auth}
                    onSave={() => {
                      getMenus({
                        user: auth.currentUser,
                        database,
                        setMenus,
                        setLoading,
                      });
                    }}
                    loading={loading}
                    setLoading={setLoading}
                    locale_t={locale_t}
                  />
                }
              />
              <Route
                path="/update/:shareKey"
                element={
                  <RecipesForm
                    database={database}
                    auth={auth}
                    loading={loading}
                    setLoading={setLoading}
                    locale_t={locale_t}
                  />
                }
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
