import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControlLabel,
  debounce,
} from "@mui/material";
import { get, ref, set } from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import { Trans } from "react-i18next";
import styled from "styled-components";
import "../../App.css";
import { SubText } from "../Styled/Text";

// TODO Implement shopping list into the metadata of the shareKey.
// TODO Consider writing the shopping list myself using open source
// tools online.
// Katie Zhang's medium article explains in a great way how to do this:
//    https://kzz.medium.com/how-i-automated-the-creation-of-my-grocery-list-from-a-bunch-of-recipe-websites-with-python-90d15e5c0c83
//
// Further, Zack Schollz has a library for extracting ingredient lists from any given recipe online:
//    https://schollz.com/tinker/ingredients/
//
// I should look to expand upon Zack Schollz's library and see if I can add a manual list of ingredients
//

const ThemeSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
}));
const ThemeField = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  width: "100%",
}));

const Swatch = styled.div`
  margin: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  border-radius: 1rem;
  boxshadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Color = styled.div(({ color }) => ({
  width: "1rem",
  height: "1rem",
  position: "relative",
  top: "0.25rem",
  left: "0.25rem",
  borderRadius: "1rem",
  background: color,
}));

const Popover = styled.div(() => ({
  position: "absolute",
  zIndex: "2",
}));

const Cover = styled.div(() => ({
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
}));

const SketchColor = ({ color, onChanged }) => {
  const [state, setState] = useState({
    displayColorPicker: false,
    color: color,
  });

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker });
  };

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false });
  };

  const handleChange = (color) => {
    setState({ ...state, color: color.hex });
  };

  return (
    <div>
      <Swatch onClick={handleClick}>
        <Color color={state.color} />
      </Swatch>
      {state.displayColorPicker ? (
        <Popover>
          <Cover onClick={handleClose} />
          <SketchPicker
            color={state.color}
            onChange={handleChange}
            onChangeComplete={onChanged}
          />
        </Popover>
      ) : null}
    </div>
  );
};

const ThemeContent = ({ theme, setTheme, locale_t }) => {
  const handleOnChanged = (color, idx) => {
    const backgroundColors = theme?.backgroundColors || [];

    backgroundColors[idx] = color.hex;
    setTheme({ ...theme, backgroundColors });
  };

  return (
    <>
      <Divider variant="middle" sx={{ margin: "1vh" }}>
        <Chip
          sx={{ backgroundColor: "#000000" }}
          label={locale_t("theme.background.title")}
        />
      </Divider>
      <ThemeSection>
        <ThemeField>
          <FormControlLabel
            sx={{ textAlign: "left", margin: "1vh" }}
            control={
              <Checkbox
                checked={!!theme?.isGradient}
                onChange={(event) => {
                  setTheme({ ...theme, isGradient: event.target.checked });
                }}
              />
            }
            label={locale_t("theme.enableGradient")}
          />
        </ThemeField>
        {theme?.isGradient ? (
          <>
            <ThemeField>
              <FormControlLabel
                sx={{ textAlign: "left", margin: "1vh" }}
                control={
                  <SketchColor
                    onChanged={(color) => handleOnChanged(color, 0)}
                    color={(theme?.backgroundColors || [])[0]}
                  />
                }
                label={locale_t("theme.background.top")}
              />
            </ThemeField>
            <ThemeField>
              <FormControlLabel
                sx={{ textAlign: "left", margin: "1vh" }}
                control={
                  <SketchColor
                    onChanged={(color) => handleOnChanged(color, 1)}
                    color={(theme?.backgroundColors || [])[1]}
                  />
                }
                label={locale_t("theme.background.bottom")}
              />
            </ThemeField>
          </>
        ) : (
          <ThemeField>
            <FormControlLabel
              sx={{ textAlign: "left", margin: "1vh" }}
              control={
                <SketchColor
                  onChanged={(color) => handleOnChanged(color, 0)}
                  color={(theme?.backgroundColors || [])[0]}
                />
              }
              label={locale_t("theme.background.single")}
            />
          </ThemeField>
        )}
      </ThemeSection>
    </>
  );
};

const updateTheme = debounce((themeRef, theme) => {
  set(themeRef, theme).then(() => {});
}, 1000);

const ThemeModal = ({
  showThemeModal,
  setShowThemeModal,
  auth,
  database,
  shareKey,
  locale_t,
}) => {
  const [theme, internalSetTheme] = useState({});
  const themeRef = useMemo(() => {
    if (!!database && !!auth?.currentUser?.uid) {
      // if (!!shareKey) {
      // return ref(
      //   database,
      //   "users/" + auth.currentUser.uid + "/theme/" + shareKey
      // );
      // TODO Do we want customized themes per menu?
      // }

      return ref(database, "users/" + auth.currentUser.uid + "/theme/galactic");
    } else {
      return undefined;
    }
  }, [auth?.currentUser?.uid, database]);

  const setTheme = useCallback(
    (theme) => {
      updateTheme(themeRef, theme);
      internalSetTheme(theme);
    },
    [themeRef]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!!themeRef) {
        const data = (await get(themeRef)).val();
        if (!!data) {
          setTheme(data);
        }
      }
    };

    fetchData();
  }, [database, shareKey, auth?.currentUser?.uid, themeRef, setTheme]);

  const handleDone = () => {
    setShowThemeModal(false);
  };
  return (
    <Dialog open={showThemeModal}>
      <DialogTitle>
        <p>{locale_t("theme.modal.title")}</p>
        <SubText style={{ fontSize: "1rem", margin: 0 }}>
          <Trans>{locale_t("theme.modal.subtitle")}</Trans>
        </SubText>
      </DialogTitle>
      <ThemeContent locale_t={locale_t} theme={theme} setTheme={setTheme} />
      <DialogActions>
        <Button onClick={() => setTheme(null)} color="primary">
          {locale_t("button.default")}
        </Button>
        <Button onClick={handleDone} color="primary">
          {locale_t("button.done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ThemeModal;
