import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Grid, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { child, get, push, ref, set } from "firebase/database";
import React, { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { IoCreateOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import RecipeModal from "../../Components/Modals/RecipeModal";
import { ActionButton } from "../../Components/Navbar/NavbarElements";
import Snack from "../../Components/Snack";
import { MainText, Title } from "../../Components/Styled/Text";
import SubscribableComponent, {
  subscribeToMenu,
} from "../../Components/Subscribable";
import { NeedsMealsComponent, RecipeCard, RenderTextField } from "./components";

const Status = {
  READY: "READY",
  NOT_AUTHOR: "NOT_AUTHOR",
  NOT_SUBSCRIBED: "NOT_SUBSCRIBED",
};

const Header = styled.div`
  z-index: 4;
  position: sticky;
  top: 56px;
  padding: 4rem;
  height: 72px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #121212;
  );
`;
const Table = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(81, 81, 81, 1);
  border-radius: 4px;
`;

const MessageScreen = styled.div`
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 4;
`;

const HeaderSection = styled.div(({ isDesktop }) => ({
  display: "flex",
  width: "100%",
  flexDirection: isDesktop ? "row" : "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ChangeNameComponent = ({
  shareKey,
  formState,
  setFormState,
  auth,
  database,
  setSnackbar,
  navigate,
  currentMenu,
  locale_t,
}) => {
  return (
    <MessageScreen>
      {currentMenu && currentMenu.name && (
        <Title>
          {locale_t("info.changeName.title", { name: currentMenu.name })}
        </Title>
      )}
      {currentMenu && currentMenu.name && (
        <MainText>
          {locale_t("info.changeName.description", { name: currentMenu.name })}
        </MainText>
      )}
      <TextField
        error={formState.menuName.error}
        sx={{ margin: "1vh" }}
        size="small"
        value={formState.menuName.value}
        label={locale_t("label.field.menuName")}
        placeholder={locale_t("label.field.menuName")}
        helperText={
          formState &&
          formState.menuName &&
          formState.menuName.error &&
          locale_t(`label.field.error.menuName`)
        }
        onChange={(event) =>
          setFormState({
            ...formState,
            menuName: { ...formState.menuName, value: event.target.value },
          })
        }
      />
      <Button
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faCalendarPlus} />}
        onClick={() =>
          subscribeToMenu({
            shareKey,
            formState,
            setFormState,
            auth,
            database,
            setSnackbar,
            navigate,
            message: locale_t("info.snack.updatedName", {
              previousName: currentMenu.name,
              currentName: formState.menuName.value,
            }),
          })
        }
      >
        {locale_t("button.changeName")}
      </Button>
    </MessageScreen>
  );
};

const saveMenuName = ({
  auth,
  database,
  shareKey,
  menuName,
  onSave,
  setSnackbar,
  locale_t,
  recipeIndex,
  setShowRecipeForm,
}) => {
  let state = "updated";

  if (!shareKey) {
    shareKey = push(child(ref(database), "menus")).key;
    state = "created";
  }

  const nameRef = ref(database, "menus/" + shareKey + "/name");
  const usersRef = ref(
    database,
    "users/" + auth.currentUser.uid + "/menus/" + shareKey
  );

  set(nameRef, menuName).then(() => {
    set(usersRef, { shareKey, name: menuName, author: true }).then(() => {
      onSave?.();

      setSnackbar((prev) => [
        ...prev,
        {
          message: locale_t("info.snack.recipeUpdated", { state, menuName }),
          key: new Date().getTime(),
        },
      ]);
    });
  });
};

const Recipes = ({ database, auth, setLoading, locale_t, onSave }) => {
  const isDesktop = useMediaQuery("(min-width:1024px)");
  const [recipes, setRecipes] = useState([]);
  const [snackbar, setSnackbar] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);
  const [status, setStatus] = useState("");
  const [menuName, setMenuName] = useState("");
  const [currentMenu, setCurrentMenu] = useState("");
  const [formState, setFormState] = useState({
    menuName: { value: "", error: false },
  });

  const navigate = useNavigate();
  const { shareKey } = useParams();
  const [showRecipeForm, setShowRecipeForm] = useState(false);
  const [recipeIndex, setRecipeIndex] = useState(false);
  const [newRecipe, setNewRecipe] = useState(false);
  const [responseState] = useState("success");
  const [needsUpdate, setNeedsUpdate] = useState(true);
  const handleEditRecipe = (recipeIndex) => {
    setRecipeIndex(recipeIndex);
    setShowRecipeForm(true);
  };

  const handleNewRecipe = () => {
    setNewRecipe(true);
  };

  const handleDeleteRecipe = (recipe) => {
    // TODO do some additional modal logic here.
  };

  useEffect(() => {
    setMenuName("");
  }, [shareKey]);

  useEffect(() => {
    if (!(recipeIndex === false) || newRecipe) {
      setShowRecipeForm(true);
    }
  }, [recipeIndex, newRecipe]);

  useEffect(() => {}, []);

  const handleShowRecipeForm = (showRecipeForm, needsUpdate) => {
    if (!showRecipeForm) {
      setRecipeIndex(false);
      setNewRecipe(false);
    }
    setShowRecipeForm(showRecipeForm);
    setNeedsUpdate(needsUpdate);
  };

  useEffect(() => {
    if (needsUpdate) {
      // On modal dismiss, refresh all recipe data.
      const menuRef = ref(database, "menus/" + shareKey);
      setLoading(true);

      get(menuRef).then((menuSnapshot) => {
        const menu = menuSnapshot.val();

        if (menu) {
          const userMenuRef = ref(
            database,
            "users/" + auth.currentUser.uid + "/menus/" + shareKey
          );

          get(userMenuRef).then((userMenuSnapshot) => {
            const userMenu = userMenuSnapshot.val();

            if (userMenu) {
              setCurrentMenu(userMenu);
              setMenuName(userMenu.name);
              if (userMenu.author === true) {
                const recipes = (menu.recipes || []).map((r) => ({
                  ...r,
                  instructions: (r.instructions || []).join("\n"),
                  ingredients: (r.ingredients || []).join("\n"),
                }));
                setRecipes(recipes);
                setLoading(false);
                setStatus(Status.READY);
              } else {
                setStatus(Status.NOT_AUTHOR);
                setLoading(false);
              }
            } else {
              // Not subscribed
              setStatus(Status.NOT_SUBSCRIBED);
            }
          });
        } else {
          setStatus(Status.READY);
        }

        setLoading(false);
      });
      setNeedsUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareKey, needsUpdate]);

  useEffect(() => {
    if (snackbar.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbar[0] });
      setSnackbar((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackbar.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackbar, messageInfo, open]);

  return (
    <div
      style={{
        position: "absolute",
        top: "60px",
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {status === Status.NOT_AUTHOR && (
        <ChangeNameComponent
          shareKey={shareKey}
          formState={formState}
          setFormState={setFormState}
          auth={auth}
          database={database}
          setSnackbar={setSnackbar}
          navigate={navigate}
          currentMenu={currentMenu}
          locale_t={locale_t}
        />
      )}
      {status === Status.NOT_SUBSCRIBED && (
        <SubscribableComponent
          shareKey={shareKey}
          formState={formState}
          setFormState={setFormState}
          menuName={menuName}
          setMenuName={setMenuName}
          auth={auth}
          database={database}
          setSnackbar={setSnackbar}
          navigate={navigate}
          currentMenu={currentMenu}
          locale_t={locale_t}
        />
      )}
      {status === Status.READY && (
        <Table>
          <Header>
            <HeaderSection isDesktop={isDesktop}>
              <div style={{ display: "flex" }}>
                <RenderTextField
                  label={"Menu"}
                  value={menuName}
                  setValue={setMenuName}
                  isDesktop={isDesktop}
                />
                <IconButton
                  onClick={() => {
                    saveMenuName({
                      auth,
                      database,
                      shareKey,
                      menuName,
                      setSnackbar,
                      onSave,
                      locale_t,
                      recipeIndex,
                      setShowRecipeForm,
                    });
                  }}
                >
                  <BsCheck2Circle />
                </IconButton>
              </div>
              <ActionButton
                sx={{ minWidth: 150, maxWidth: 150 }}
                variant={"outlined"}
                size="small"
                startIcon={<IoCreateOutline />}
                onClick={handleNewRecipe}
              >
                {locale_t("button.addRecipe")}
              </ActionButton>
            </HeaderSection>
          </Header>
          <Divider style={{ margin: "8px" }} />
          {recipes?.length > 0 ? (
            <Grid style={{ justifyContent: "center" }} container spacing={2}>
              {recipes.map((recipe, idx) => (
                <Grid key={recipe.id} item spacing={2}>
                  <RecipeCard
                    recipe={{ ...recipe, id: recipe?.id || idx }}
                    locale_t={locale_t}
                    handleEditRecipe={handleEditRecipe}
                    handleDeleteRecipe={handleDeleteRecipe}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <NeedsMealsComponent
              currentMenu={currentMenu}
              shareKey={shareKey}
              locale_t={locale_t}
            />
          )}
        </Table>
      )}
      <Snack
        open={open}
        setOpen={setOpen}
        messageInfo={messageInfo}
        setMessageInfo={setMessageInfo}
        severity={responseState}
      />
      <RecipeModal
        showRecipeForm={showRecipeForm}
        setShowRecipeForm={handleShowRecipeForm}
        setNeedsUpdate={setNeedsUpdate}
        auth={auth}
        database={database}
        shareKey={shareKey}
        locale_t={locale_t}
        currentMenu={currentMenu}
        recipeIndex={recipeIndex}
        newRecipe={newRecipe}
      />
    </div>
  );
};

export default Recipes;
