import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { get, ref, set } from "firebase/database";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import "../../App.css";
import {
  createShoppingList,
  getAllIngredients,
} from "../../assets/ShoppingListUtil";
import { DOW, getMeals } from "../../assets/util";
import { SubText } from "../Styled/Text";
import SubscriptionTable from "../Subscription/Index";
import MealConfig from "./MealConfig";

const MenuContent = ({ locale_t, menuData, rerollRequested, rerollData }) => {
  return (
    <>
      {/* <Box style={style}> */}
      <DialogTitle>
        <p>{locale_t("info.menuWizard.title")}</p>
        <SubText style={{ fontSize: "1rem", margin: 0 }}>
          {locale_t("info.menuWizard.subtitle")}
        </SubText>
      </DialogTitle>
      <DialogContent dividers>
        <MealConfig
          data={menuData}
          rerollRequested={rerollRequested}
          rerollData={rerollData}
        />
      </DialogContent>
    </>
  );
};

export const ShoppingListContent = ({
  locale_t,
  menuData,
  rerollRequested,
  rerollData,
}) => {
  const RenderCheckbox = ({ label }) => (
    <FormControlLabel
      sx={{ textAlign: "left", margin: "1vh" }}
      control={<Checkbox />}
      label={label}
    />
  );

  const List = ({ shoppingList }) => {
    return (
      <FormControl
        sx={{
          marginTop: "4vh",
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        component="fieldset"
        variant="standard"
      >
        <FormGroup>
          {shoppingList.map((item) => (
            <RenderCheckbox key={item} label={item} />
          ))}
        </FormGroup>
      </FormControl>
    );
  };

  const allIngredients = useMemo(() => getAllIngredients(menuData), [menuData]);

  return (
    <>
      {true ? (
        <>
          <DialogTitle>
            <p>{locale_t("info.menuWizard.listTitle")}</p>
            <SubText style={{ fontSize: "1rem", margin: 0 }}>
              <Trans>{locale_t("info.menuWizard.listSubtitle")}</Trans>
            </SubText>
          </DialogTitle>
          <DialogContent dividers>
            <List shoppingList={createShoppingList(allIngredients)} />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            <p>{locale_t("info.menuWizard.noSubscription.title")}</p>
          </DialogTitle>
          <DialogContent dividers>
            <SubscriptionTable locale_t={locale_t} />
          </DialogContent>
        </>
      )}
    </>
  );
};

const WizardModal = ({ auth, database, shareKey, data, locale_t }) => {
  const [modalOpen, setModalOpen] = useState(!!data);
  const [rerollRequested, setRerollRequested] = useState(false);
  const [rerolled, setRerolled] = useState(false);
  const [rerollData, setRerollData] = useState(undefined);
  const [menuData, setMenuData] = useState(data);
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    setMenuData(data);
  }, [data]);

  useEffect(() => {
    setModalOpen(!!menuData);

    if (!!data) {
      const weekConfig = DOW.map((day, index) => data[day].id);
      if (weekConfig.every((id, idx) => id === (data?.rerollIds || [])[idx]))
        setRerolled(true);
    }
  }, [data, menuData]);

  const handleRequestReroll = () => {
    getMeals({ auth, database, shareKey, ids: data.rerollIds }).then(
      ({ recipeData }) => {
        setRerollData(recipeData);
        setRerollRequested(true);
      }
    );
  };
  const handleCancelReroll = () => {
    setRerollRequested(false);
  };

  const handleViewList = () => {
    setShowList(!showList);
  };

  const handleReroll = () => {
    setRerolled(true);
    setRerollRequested(false);
    getMeals({
      auth,
      database,
      shareKey,
      ids: data.rerollIds,
      forcePush: true,
    }).then(({ recipeData }) => {
      setRerolled(true);
      setRerollRequested(false);
      setMenuData(recipeData);
    });
  };

  const handleAccept = () => {
    const weekRef = ref(database, "menus/" + shareKey + "/MOTW");
    get(weekRef).then((snapshot) => {
      const data = snapshot.val();
      set(weekRef, { ...data, ack: true });
    });
    setModalOpen(false);
  };

  return (
    <Dialog open={modalOpen}>
      {!showList ? (
        <MenuContent
          locale_t={locale_t}
          menuData={menuData}
          rerollRequested={rerollRequested}
          rerollData={rerollData}
        />
      ) : (
        <ShoppingListContent locale_t={locale_t} menuData={menuData} />
      )}
      <DialogActions>
        <Button
          onClick={() => {
            handleViewList();
          }}
          color="primary"
        >
          {locale_t(!showList ? "button.viewList" : "button.viewMenu")}
        </Button>

        {!rerolled && !rerollRequested ? (
          <Button
            onClick={() => {
              handleRequestReroll();
            }}
            color="primary"
          >
            {locale_t("button.reroll")}
          </Button>
        ) : (
          rerollRequested && (
            <Button
              onClick={() => {
                handleCancelReroll();
              }}
              color="primary"
            >
              {locale_t("button.cancelReroll")}
            </Button>
          )
        )}

        {rerollRequested ? (
          <Button
            onClick={() => {
              handleReroll();
            }}
            color="primary"
          >
            {locale_t("button.acceptReroll")}
          </Button>
        ) : (
          <Button
            onClick={() => {
              handleAccept();
            }}
            color="primary"
          >
            {locale_t("button.closeAndAccept")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WizardModal;
