import { withStyles } from "@material-ui/core/styles";
import { ToggleButton } from "@mui/material";
import React from "react";
import { SubText } from "../Styled/Text";

import { Trans } from "react-i18next";
import { NUMBERED_DAYS } from "../../assets/util";

// https://codesandbox.io/s/days-of-the-week-picker-w0txd?file=/src/ToggleDays.js:0-1914
// :D
const DAYS = [
  {
    key: "sunday",
    label: "S",
  },
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
];

const StyledToggle = withStyles({
  root: {
    color: "#FFFFFF",
    "&$selected": {
      color: "white",
      background: "#888888",
    },
    "&:hover": {
      borderColor: "#777777",
      background: "#777777",
    },
    "&:hover$selected": {
      borderColor: "#3ec3ff",
      background: "#3ec3ff",
    },
    borderWidth: 2,
    borderColor: "#aaa",
    minWidth: 64,
    maxWidth: 64,
    height: 64,
    textTransform: "unset",
    fontSize: "0.75rem",
  },
  selected: {},
})(ToggleButton);

const DayToggle = ({ selected, setSelected, locale_t }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        alignSelf: "center",
      }}
    >
      {/* <ToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        onChange={(event, value) => setDays(value)}
      > */}
      {DAYS.map((day, index) => (
        <StyledToggle
          sx={{ width: 72, height: 72, borderRadius: 28, margin: 0.5 }}
          key={day.key}
          value={index}
          selected={selected === NUMBERED_DAYS[day.key]}
          onChange={() => {
            setSelected(NUMBERED_DAYS[day.key]);
          }}
          aria-label={day.key}
        >
          {day.label}
        </StyledToggle>
      ))}
      {/* </ToggleButtonGroup> */}
      <SubText style={{ fontSize: "1rem", margin: 0, marginTop: 16 }}>
        <Trans>
          {!!selected
            ? locale_t(`info.menuWizard.dayNotes.${DAYS[selected].key}`)
            : ""}
        </Trans>
      </SubText>
    </div>
  );
};

export default DayToggle;
