import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { get, ref, set } from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import "../../App.css";
import RecipesForm from "../../Routes/Recipes/RecipesFormModal";
import { getNextGeneration } from "../../assets/util";
import { SubText } from "../Styled/Text";

const RecipeContent = ({
  database,
  auth,
  setMenus,
  shareKey,
  setLoading,
  loading,
  selected,
  setSelected,
  locale_t,
  recipeIndex,
  newRecipe,
  setNeedsUpdate,
}) => {
  return (
    <RecipesForm
      database={database}
      auth={auth}
      shareKey={shareKey}
      loading={loading}
      setLoading={setLoading}
      locale_t={locale_t}
      recipeIndex={recipeIndex}
      newRecipe={newRecipe}
      setNeedsUpdate={setNeedsUpdate}
    />
  );
};

const RecipeModal = ({
  showRecipeForm,
  setShowRecipeForm,
  setNeedsUpdate,
  auth,
  database,
  shareKey,
  locale_t,
  recipeIndex,
  newRecipe,
  currentMenu,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();
  const [menuData, setMenuData] = useState({});
  const menuRef = useMemo(() => {
    if (!!database && !!auth?.currentUser?.uid && !!shareKey) {
      return ref(
        database,
        "users/" + auth.currentUser.uid + "/menus/" + shareKey
      );
    } else {
      return undefined;
    }
  }, [auth?.currentUser?.uid, database, shareKey]);

  useEffect(() => {
    const fetchData = async () => {
      if (!!menuRef && auth?.currentUser?.uid) {
        const data = (await get(menuRef)).val();
        if (!!data) {
          setSelected(data.dayGeneration);
          setMenuData(data);
        }
      }
    };

    fetchData();
  }, [database, shareKey, auth?.currentUser?.uid, menuRef]);

  const handleDone = () => {
    setShowRecipeForm(false);
  };

  const handleSubmit = () => {
    dispatch(submit("recipe"));
  };

  const handleSelected = useCallback(
    (dayGeneration) => {
      const newMenuData = { ...menuData, dayGeneration };

      set(menuRef, newMenuData).then(() => {
        setSelected(dayGeneration);

        const weekRef = ref(database, "menus/" + shareKey + "/MOTW");
        get(weekRef).then((snapshot) => {
          const data = snapshot.val();
          set(weekRef, { ...data, until: getNextGeneration(dayGeneration) });
        });
      });
    },
    [database, menuData, menuRef, shareKey]
  );

  return (
    <Dialog open={showRecipeForm}>
      {/**TODO CHANGE TO showRecipeForm*/}
      <DialogTitle>
        <p>
          {locale_t("info.menuWizard.recipeTitle", { menu: currentMenu?.name })}
        </p>
        <SubText style={{ fontSize: "1rem", margin: 0 }}>
          <Trans>{locale_t("info.menuWizard.recipeSubtitle")}</Trans>
        </SubText>
      </DialogTitle>
      <RecipeContent
        database={database}
        auth={auth}
        shareKey={shareKey}
        locale_t={locale_t}
        selected={selected}
        setSelected={handleSelected}
        recipeIndex={recipeIndex}
        newRecipe={newRecipe}
        setNeedsUpdate={setNeedsUpdate}
      />
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          {locale_t("button.save")}
        </Button>
        <Button onClick={handleDone} color="primary">
          {locale_t("button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecipeModal;
